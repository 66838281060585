import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { List } from 'reselect/es/types';
import { getViewFileArchivioScheda } from 'services/documentiService';
import { brotliDecompress } from 'zlib';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    //onPositive: () => void;
    onNegative: () => void;
    tipointervento:ReactNode;
    tiporinnovo: ReactNode;
    azienda:ReactNode;
    impianto:ReactNode;
    installatore:ReactNode;
    utente?:ReactNode
    tipoUtente:ReactNode;
    foto?: any;
    archivio?: string
};

const DetailElencoImpreseModal: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    //onPositive,
    onNegative,
    disableButton,
    tipointervento,
    tiporinnovo,
    azienda,
    impianto,
    installatore,
    utente,
    tipoUtente,
    foto,
    archivio
}) => {

    const handleFileClick = (images) => {
        handleViewFile(body["foto"],images,body["azienda"]);
    }

    const base64toBlobPDF = (data: string) => {
        const base64WithoutPrefix = data;

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const base64toBlobImage = (data: string, tipo: string) => {
        const base64WithoutPrefix = data;

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'image/'+ tipo });
    };

    const handleViewFile = (archivio, file, utente) => {
        let action = 1; 
        if(file.includes(".zip")){
            // action = 1;
            // getViewFileArchivioScheda(archivio, file, utente).then(response => {
            //     const blob = base64toBlobZIP(response.data.response);
            //     const fileURL = URL.createObjectURL(blob);
            //     window.open(fileURL, "_blank");
            //     setIsLoading(false);
            // })
            //     .catch(error => {
            //         //console.log(error);
            //         setIsLoading(false);
            // });
        }else if(file.includes(".pdf")){
            getViewFileArchivioScheda(archivio, file, utente).then(response => {
                const blob = base64toBlobPDF(response.data.response);
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL, "_blank");
                //setIsLoading(false);
            })
                .catch(error => {
                    //console.log(error);
                    //setIsLoading(false);
            });
        }else if(file.includes(".jpg")){
            getViewFileArchivioScheda(archivio, file, utente).then(response => {
                const blob = base64toBlobImage(response.data.response, "jpg");
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL, "_blank");
                //setIsLoading(false);
            })
                .catch(error => {
                    //console.log(error);
                    //setIsLoading(false);
            });
        }else if(file.includes(".jpeg")){
                getViewFileArchivioScheda(archivio, file, utente).then(response => {
                    const blob = base64toBlobImage(response.data.response, "jpeg");
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, "_blank");
                    //setIsLoading(false);
                })
                    .catch(error => {
                        //console.log(error);
                        //setIsLoading(false);
                });
            }else if(file.includes(".png")){
                getViewFileArchivioScheda(archivio, file, utente).then(response => {
                    const blob = base64toBlobImage(response.data.response, "png");
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, "_blank");
                    //setIsLoading(false);
                })
                    .catch(error => {
                        //console.log(error);
                        //setIsLoading(false);
                });
        }else{
            //setIsLoading(false);
            //handleNavigateElenco(row)
        }

    }

    if(foto.length<1) foto = null;

    console.log(body)
    if(body != null){
        return (
            <Modal size="xl" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-6 heigthRigaElenco'>Data: <strong>{body["data"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Costo: <strong>{body["costo"]}€ (IVA Inclusa)</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Tipo Intervento: <strong>{tipointervento}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Tipo Rinnovo: <strong>{tiporinnovo}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Azienda: <strong>{azienda}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Installatore: <strong>{installatore}</strong></div>
                        {tipoUtente != "UTENTE" &&
                        <div className='col-sm-12 heigthRigaElenco'>Utente: <strong>{utente}</strong></div>
                        }
                        <div className='col-sm-6 heigthRigaElenco'>Impianto: <strong>{impianto}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Indirizzo: <strong>{body["indirizzo"]}</strong></div>
                        <div className='col-sm-12 heigthRigaElenco'>Descrizione: <strong>{body["descrizione"]}</strong></div>
                        {foto != null &&
                            <div className='col-sm-12 heigthRigaElenco'>File: </div>
                        }
                        {foto != null && foto.map((elemento) =>{
                            
                            return(
                                <>
                                <div className='col-sm-6 heigthRigaElenco'>
                                <Button key={elemento} variant="link" className="collapse-item pt-0" onClick={() => handleFileClick(elemento)}>
                                <i className="fas fa-fw fa-file"></i><span>{elemento}</span></Button>
                                </div>
                                </>
                                
                            )
                        })}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                    {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                        {buttonPositive}
                    </Button> */}
                </Modal.Footer>
            </Modal>
        );
    }else{
        return(
            <Modal size="lg" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Richiesta scaduta, riprova la ricerca e richiedere dettaglio</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    }
};

export default DetailElencoImpreseModal;

