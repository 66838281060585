import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITipo_Impianto {
id:number,
descrizione:string,
alimentazione:string
}

interface ITipo_ImpiantoData {
    list?: Array<ITipo_Impianto>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ITipo_ImpiantoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const tipo_impiantoSlice = createSlice({
    name: "tipo_impianto",
    initialState,
    reducers: {
        setTipo_ImpiantoList: (state, _action: PayloadAction<ITipo_ImpiantoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetTipo_ImpiantoToInit: (state) => {
            state = initialState;
        },
        setTipo_ImpiantoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setTipo_ImpiantoList, resetTipo_ImpiantoToInit, setTipo_ImpiantoMessage } = tipo_impiantoSlice.actions;

export default tipo_impiantoSlice.reducer;

