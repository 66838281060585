import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { resetAziendaToInit, setAziendaList, setInstallatoreMessage, setLoginMessage } from "redux/actions";
import { resetTipo_LoginToInit, setTipo_LoginList } from "redux/actions";

import { getTipo_Login } from "services/tipo_loginService";

import { useAppDispatch } from "redux/store";
import { addLogin, updateLogin } from "services/loginService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { getAzienda } from "services/aziendaService";
import { sendEmail } from "services/emailService";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const LoginForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',username:'',password:'',tipo_login:'',id_registrante:'',tipo_registrante:''};
    const initialValue = action === 'edit' ? row : iValue;
    const tipo_loginData = useSelector((state: RootState) => state.tipo_login);
    const aziendaData = useSelector((state: RootState) => state.azienda);

    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")

    useEffect(() => {
if (tipo_loginData && tipo_loginData.list && tipo_loginData.list.length === 0) {
            dispatch(resetTipo_LoginToInit());
            getTipo_Login(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setTipo_LoginList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setLoginMessage("No Record Found For Tipo_Login"));
                }
              })
        }
        if (aziendaData && aziendaData.list && aziendaData.list.length === 0) {
            dispatch(resetAziendaToInit());
            getAzienda(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setAziendaList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setInstallatoreMessage("No Record Found For Azienda"));
                }
              })
        }
})

const handleemail = () => {
    const data = {
        "to": "tecnoinnovis@gmail.com",
        "body": "test invio",
        "subject": "Invio",
        'tipo': "SCHEDAUTENTE",
        'utente': "Mario Rossi",
        'installatore' : "Mazza Impianti",
        'impianto': "Impianto codizionatore roma"

    }
    sendEmail(data).then((response) => {
        console.log(response)
    })

  }

  
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                if(tipoUtente === "AZIENDA"){
                    values.id_registrante = aziendaData.list[0].id
                    values.tipo_registrante = "AZIENDA"
                    values.tipo_login = 2
                }
                if(tipoUtente === "INSTALLATORE"){
                    values.id_registrante = aziendaData.list[0].id
                    values.tipo_registrante = "INSTALLATORE"
                    values.tipo_login = 2
                }
                const response = await updateLogin(values);
                if (response) {
                    dispatch(setLoginMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setLoginMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                if(tipoUtente === "AZIENDA"){
                    values.id_registrante = aziendaData.list[0].id
                    values.tipo_registrante = "AZIENDA"
                    values.tipo_login = 2
                }
                if(tipoUtente === "INSTALLATORE"){
                    values.id_registrante = aziendaData.list[0].id
                    values.tipo_registrante = "INSTALLATORE"
                    values.tipo_login = 2
                }
                const response = await addLogin(values);
                if (response) {
                    dispatch(setLoginMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');

                } else {
                    dispatch(setLoginMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           username: yup.string().required('username is required'),
password: yup.string().required('password is required'),
// id_registrante: yup.number().nullable(),
// tipo_registrante: yup.string().nullable(),
// tipo_login: yup.string().required('tipo_login is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
            <>
                    <p className="no-margin-bottom">
                        <a className="btn-icon-split" onClick={() => hideShowForm(false)}>
                            <p className="no-margin-bottom">
                                <span className="">
                                    <i className="fas fa-arrow-left"></i>
                                </span>
                                <span className="text">Lista</span>
                            </p>
                        </a>
                    </p>
                    <p className="m-0 font-weight-bold text-primary text-capitalize">Nuova Scheda Intervento</p>
                </>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">username</label>
<Form.Control type="text" name="username" className="form-control" value={formik.values.username}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.username && !!formik.errors.username}
isValid ={!!formik.touched.username && !formik.errors.username}
></Form.Control>
{
    formik.errors.username && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.username}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">password</label>
<Form.Control type="password" name="password" className="form-control" value={formik.values.password}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.password && !!formik.errors.password}
isValid ={!!formik.touched.password && !formik.errors.password}
></Form.Control>
{
    formik.errors.password && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.password}
    </Form.Control.Feedback>
)}
</Form.Group>
{/* <Form.Group>
<label className="form -control-label">id_registrante</label>
<Form.Control type="text" name="id_registrante" className="form-control" value={formik.values.id_registrante}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.id_registrante && !!formik.errors.id_registrante}
isValid ={!!formik.touched.id_registrante && !formik.errors.id_registrante}
></Form.Control>
{
    formik.errors.id_registrante && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_registrante}
    </Form.Control.Feedback>
)}
</Form.Group> */}
{tipoUtente != "AZIENDA" && tipoUtente != "INSTALLATORE" &&
<>
<Form.Group>
    <label className="form -control-label">id azienda registrante</label>
    <Form.Control as="select"  name="id_registrante" className="form-control" value={formik.values.id_registrante}
    onChange={formik.handleChange}
    onBlur ={formik.handleBlur}
    isInvalid ={!!formik.touched.id_registrante && !!formik.errors.id_registrante}
    isValid ={!!formik.touched.id_registrante && !formik.errors.id_registrante}
    >
    <option value={0}>Select Azienda </option> 
    {
    aziendaData.list.map((item, i) => {
    return <option value={item.id} key={`azienda-${i}`}>{item.denominazione}</option>
    })}
    </Form.Control>
{
    formik.errors.id_registrante && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.id_registrante}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">tipo_registrante</label>
<Form.Control type="text" name="tipo_registrante" className="form-control" value={formik.values.tipo_registrante}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.tipo_registrante && !!formik.errors.tipo_registrante}
isValid ={!!formik.touched.tipo_registrante && !formik.errors.tipo_registrante}
></Form.Control>
{
    formik.errors.tipo_registrante && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.tipo_registrante}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">tipo_login</label>
<Form.Control as="select"  name="tipo_login" className="form-control" value={formik.values.tipo_login}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.tipo_login && !!formik.errors.tipo_login}
isValid ={!!formik.touched.tipo_login && !formik.errors.tipo_login}
>
<option value={0}>Select Tipo_Login </option> 
{
tipo_loginData.list.map((item, i) => {
return <option value={item.id} key={`tipo_login-${i}`}>{item.descrizione}</option>
})}
</Form.Control>
{
    formik.errors.tipo_login && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.tipo_login}
    </Form.Control.Feedback>
)}
</Form.Group>
</>
    
}



                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

