import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetUtenteToInit, setUtenteMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteUtente } from "services/utenteService";
import ReactPaginate from "react-paginate";
import DetailUtente from "template/DetailUtente";
type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const UtenteTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);

    const [postsPerPage] = useState(10);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0);
    

    const elencoImprese: any [] = []
    const [lstElencoImprese, setListElencoImprese] = useState(elencoImprese);
    const [lstElencoImpreseCompleto, setListElencoImpreseCompleto] = useState(elencoImprese);
    const [countImprese, setcountImprese] = useState(elencoImprese.length);

    const [showDetailElenco, setShowDetailElenco] = useState(false);
    const [row, setRow] = useState(undefined);


    const rData = useSelector((state: RootState) => state.utente);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetUtenteToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
        }else{
            getAllPosts(rData.list);
            setListElencoImprese(rData.list)
        }
    },[rData, rData.list.length, offset])
    const handleReset = () => {
        setSearch('');
        dispatch(resetUtenteToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteUtente(rowData.id);
            if (response) {
                dispatch(resetUtenteToInit());
                dispatch(setUtenteMessage("Utente eliminato con successo"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setUtenteMessage("Elimina utente non eseguito! Riprovare"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setUtenteMessage(''));
        hideShowForm('add');
    }

    const columns = [
       {name: 'id', selector: row => row.id, sortable: true},
{name: 'nome', selector: row => row.nome, sortable: true},
{name: 'cognome', selector: row => row.cognome, sortable: true},
{name: 'codicefiscale', selector: row => row.codicefiscale, sortable: true},
{name: 'citta', selector: row => row.citta, sortable: true},
{name: 'cap', selector: row => row.cap, sortable: true},
{name: 'indirizzo', selector: row => row.indirizzo, sortable: true},
{name: 'telefono', selector: row => row.telefono, sortable: true},
{name: 'email', selector: row => row.email, sortable: true},
{name: 'login', selector: row => row.login, sortable: true},

        ,{
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];


    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const getAllPosts = async (elenco_input) => {
        let res = rData.list//rDataElenco
        if(elenco_input != null && elenco_input.length > 0){
            res = elenco_input
        }
        const data = res
        let slice = null;
        if(res.length < 11){
             //res.list;
            slice = data
        }else{

            if(offset > 1){
                slice = data.slice((offset - 1) * 10 , (((offset - 1) * 10) + postsPerPage) -1)
            }else{
                slice = data.slice(offset - 1 , offset - 1 + postsPerPage)
            }
            
        }
        // For displaying Data
        const postData = getPostData(slice)
      
        // Using Hooks to set value
        setAllPosts(postData)
        setPageCount(Math.ceil(data.length / postsPerPage))

        setcountImprese(res.length)
    }

    const getPostData = (data) => {
        return (
          data.map(item => <div className="" key={item.id}>
            <div className={borderColorInfo(item.anagraficaimpresa)}>
                            <div className="row">
                                {/* <div className="col-sm-1 center-block-custom">
                                    {handleRowDettaglio(item)}
                                </div> */}
                                <div className="col-sm-9">
                                    <div className="row">
                                        <div className="col-sm-6"><p className="no-margin-bottom">Utente:</p><p className="no-margin-bottom"><strong>{item.cognome + " " + item.nome}</strong></p></div>
                                        <div className="col-sm-6"><p className="no-margin-bottom">Codice Fiscale:</p><p className="no-margin-bottom"><strong>{item.codicefiscale}</strong></p></div>
                                        <div className="col-sm-6"><p className="no-margin-bottom">Città:</p><p className="no-margin-bottom"><strong>{item.citta}</strong></p></div>
                                    </div>
                                </div>
                                <div className="col-sm-3 center-div-block">
                                {handleRowDettaglio(item)} {handleRowEditAction(item)} {handleRowDeleteAction(item)}
                                </div>  
                                {/* <div className="col-sm-1 center-div-block">
                                    {handleRowDeleteAction(item)}
                                </div>  */}
                            </div>
                        </div>
          </div>)
        )
      
      }


      const filterBySearch = (event) => {
        const query = event
        let query_stato = query
        var updatedList = lstElencoImprese
        updatedList = updatedList.filter((item) => {
            if(
            (item.cognome != null && item.cognome.toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
            (item.nome != null && item.nome.toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
            (item.codicefiscale != null && item.codicefiscale.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
            (item.citta != null && item.citta.toLowerCase().indexOf(query.toLowerCase()) !== -1)
            ){
                return item
            }
        });
        // Trigger render with updated values
        setListElencoImprese(updatedList)
        getAllPosts(updatedList)
    };

    const handleSearchButton = () => {

        filterBySearch(search)
    
    }

    const borderColorInfo = (row) => {
        return "col-sm-12 riga-trova-impresa border-left-8-green background-row-white shadow";
    }

    const handleRowDettaglio = (row) => {

            return(<Button variant="link" className="btn-sm"
             onClick={() => handleRowDetail(row)}>
                <i className="fa fa-search-plus icon-size-normal"></i></Button>);
      }

    const handleRowEditAction = (row) => {

        return(<Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>
            <i className="fa fa-pencil icon-size-normal"></i></Button>);
    }
    const handleRowDeleteAction = (row) => {

        return(<Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>
            <i className="fa fa-trash icon-size-normal"></i></Button>);
    }

      const handleRowDetail = (rowData) => {
        console.log(rowData)
        setRow(rowData)
        setShowDetailElenco(true);

      }



    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">Utente ({rData.totalCount})
                    <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                    <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        {/* <span className="text">Add New</span> */}
                    </Button></h6>

            </Card.Header>
            <Card.Body>
                <Col className="ml-auto" md={3} xs={12} xl={3}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Cerca"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearchButton}>
                            Cerca
                        </Button>
                    </InputGroup>
                </Col>
                <Row>
                <div className="main-app">

                <ReactPaginate
                    previousLabel={"pre"}
                    nextLabel={"succ"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    //subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                    {/* Display all the posts */}
                    {posts}
                    {/* Using React Paginate */}
                 </div>
                </Row>
                
                {/* <div className="table-responsive">
                    <DataTable
                        selectableRows={true}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={columns} data={rData.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        onChangePage={handlePageChange}></DataTable>
                </div> */}
            </Card.Body>
            <ConfirmationModal buttonNegative="Annulla" buttonPositive="Elimina" title="Conferma Elimina" show={showDelete} body={"Sei sicuro di voler eliminare questo utente?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
            <DetailUtente
            buttonNegative="Chiudi" 
            buttonPositive="Chiudi" 
            title="Dettaglio Impianto" 
            show={showDetailElenco} 
            body={row} 
            onNegative={() => setShowDetailElenco(false)} />
        </Card>
    );
}

