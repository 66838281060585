import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAppDispatch } from "redux/store";
import { tokenAPICALL, resetPasswordAPICALL } from "services/authService";
import { setJWTToken } from "redux/actions";
import { setError } from "redux/slices/auth";
import { Alert, Button, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { Constant } from "template/Constant";
const ResetPassword: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const rData = useSelector((state: RootState) => state.authToken);
    const[messaggioErrore, setMessaggioErrore] = useState(null)
    const[messaggioCambio, setMessaggioCambio] = useState(null)
    const searchParam = window.location.search;
    const params = new URLSearchParams(searchParam)
  
    const accessToken = params.get('reference')
    const formik = useFormik({
        initialValues: {
            confirmpassword: '',
            password: ''
        },
        onSubmit: async (values) => {
            dispatch(setError(null))
            setMessaggioErrore(null)
            setMessaggioCambio(null)
            if(values.password != values.confirmpassword){
                setMessaggioErrore("Password e Conferma Password non coincidono!")
            }else{
                
                const response = await resetPasswordAPICALL(values.password, values.confirmpassword,accessToken);
                if (response.document) {
                    //dispatch(setJWTToken(response));
                    //navigate('/dashboard', { replace: true });
                    setMessaggioCambio(response.message)
                    console.log(response)
                } else {
                    dispatch(setError(response.message))
                }
            }
        },
        validationSchema: yup.object({
            confirmpassword: yup.string().trim().required('Conferma Password è obbligatorio'),
            password: yup.string().trim().required('Password è obbligatorio'),
        }),
    });

    useEffect(() => {
        document.body.classList.toggle('bg-gradient-primary', true);
    }, [])
    return (

        <div className="container">
            <div className="row justify-content-center">
                <div className="col-xl-10 col-lg-12 col-md-9">
                    <div className="card o-hidden border-0 shadow-lg my-5">
                        <div className="card-body p-0">
                            <div className="row">
                                {/* <div className="col-lg-6 d-none d-lg-block bg-login-image"></div> */}
                                <div className="col-lg-12">
                                    <div className="p-5">
                                        <div className="text-center">
                                            <h1 className="h4 text-gray-900 mb-4">Reset Password</h1>
                                        </div>
                                        <Form className="user" onSubmit={formik.handleSubmit}>
                                            <Form.Group>
                                                <label className="form-control-label">Password</label>
                                                <Form.Control type="password" name="password" className="form-control-user" value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.password && !!formik.errors.password}
                                                    isValid={!!formik.touched.password && !formik.errors.password}
                                                ></Form.Control>
                                                {formik.errors.password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.password}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group>
                                                <label className="form-control-label">Conferma Password</label>
                                                <Form.Control type="password" name="confirmpassword" className="form-control-user" value={formik.values.confirmpassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    isInvalid={!!formik.touched.password && !!formik.errors.confirmpassword}
                                                    isValid={!!formik.touched.password && !formik.errors.confirmpassword}
                                                ></Form.Control>
                                                {formik.errors.password && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formik.errors.confirmpassword}
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            
                                            <Button type="submit" className="btn-user btn-block" variant="primary">Reset Password</Button>


                                        </Form>
                                        <hr />
                                        {rData.errorMessage ?
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{rData.errorMessage}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert> : null}
                                        
                                        {messaggioErrore &&
                                            <Alert variant={Constant.defaultAlertVarient} className="alert-dismissible fade">{messaggioErrore}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }
                                        {messaggioCambio &&
                                            <Alert variant={Constant.defaultAlertSuccess} className="alert-dismissible fade">{messaggioCambio}
                                                <Button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => dispatch(setError(''))}>
                                                    <span aria-hidden="true">&times;</span>
                                                </Button>
                                            </Alert>
                                        }
                                        
                                        <div className="copyright text-center my-auto">
                                            <span>Copyright &copy; myimpianto 2022</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

        </div>
    );
};

export default ResetPassword;


