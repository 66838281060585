import React, { useEffect, useState } from "react";
import { Col, Card } from "react-bootstrap";
import { useNavigate } from "react-router";
import { activateMenu } from "redux/actions";
import { useAppDispatch } from "redux/store";
import { getAzienda } from "services/aziendaService";
import { getInstallatore } from "services/installatoreService";
import { getUtente } from "services/utenteService";
type Props = {
    name: string;
    path: string;
};
export const DashboardUtente: React.FC<Props> = ({ name, path }) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const handleCardClick = (path) => {
        dispatch(activateMenu(path))
        navigate(path, { replace: true });
    }

    const [datiUtente, setDatiUtente] = useState(undefined);
    const [datiInstallatori, setDatiInstallatori] = useState([]);


    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")

    useEffect(() => {
        if (datiUtente == null) {
            getUtente(1, 1000, "").then((response) => {
                console.log(response)
                if (response && response.records) {
                  setDatiUtente(response.records[0])
                }
              })
        }
        if (datiInstallatori == null) {
            getInstallatore(1, 1000, "").then((response) => {
                console.log(response)
                if (response && response.records) {
                    setDatiInstallatori(response.records)
                }
              })
        }
    },[datiUtente,datiInstallatori])


    return (
        <>
        <Col md={12} xl={12} xs={12} className="marginb-25 cursor-pointer mb-12" onClick={() => { handleCardClick(path); }}>
            <Card className="shadow h-100 py-2 border-left-8-green">
                <Card.Body>
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className="text-primary text-uppercase mb-1">
                                <div className="row">
                                    <div className="col-sm-12 font-weight-bold h4"><span className="font-weight-bold">{datiUtente != null && datiUtente.cognome + " " + datiUtente.nome}</span></div>
                                    {/* <div className="col-sm-12">Denominazione: <span className="font-weight-bold">{datiUtente != null && datiUtente.denominazione}</span></div> */}
                                    <div className="col-sm-6">Codice Fiscale/PIva: <span className="font-weight-bold">{datiUtente != null && datiUtente.codicefiscale}</span></div>
                                    <div className="col-sm-6">Email: <span className="font-weight-bold">{datiUtente != null && datiUtente.email}</span></div>
                                    <div className="col-sm-6">Telefono: <span className="font-weight-bold">{datiUtente != null && datiUtente.telefono}</span></div>
                                    <div className="col-sm-12">Indirizzo: <span className="font-weight-bold">{datiUtente != null && datiUtente.indirizzo + " (" + datiUtente.cap + ") " + datiUtente.citta}</span></div>
                                </div>
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                        <div className="col-auto">
                            <i className="fas fa-building fa-2x text-gray-300"></i>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        {datiInstallatori.length > 0 &&
        <Col md={12} xl={12} xs={12} className="marginb-25 cursor-pointer mb-12" onClick={() => { handleCardClick(path); }}>
            <Card className="border-left-primary shadow h-100 py-2">
                <Card.Body>
                    <div className="row no-gutters align-items-center">
                        <div className="col mr-2">
                            <div className="text-primary text-uppercase mb-1">
                            {datiInstallatori.map((installatore) =>{
                                return(
                                <div className="row">
                                    <div className="col-sm-12 font-weight-bold h5">Installatore</div>
                                    <div className="col-sm-12">Denominazione: <span className="font-weight-bold">{installatore != null && installatore.denominazione}</span></div>
                                    <div className="col-sm-6">Codice Fiscale/PIva: <span className="font-weight-bold">{installatore != null && installatore.codicefiscale}</span></div>
                                    <div className="col-sm-6">Referente: <span className="font-weight-bold">{installatore != null && installatore.cognome} {installatore != null && installatore.nome}</span></div>
                                    <div className="col-sm-6">Email: <span className="font-weight-bold">{installatore != null && installatore.email}</span></div>
                                    <div className="col-sm-6">Telefono: <span className="font-weight-bold">{installatore != null && installatore.telefono}</span></div>
                                    <div className="col-sm-12">Indirizzo: <span className="font-weight-bold">{installatore != null && installatore.indirizzo + " (" + installatore.cap + ") " + installatore.citta}</span></div>
                                </div>)
                                })
                            }
                            </div>
                            <div className="h5 mb-0 font-weight-bold text-gray-800"></div>
                        </div>
                        <div className="col-auto">
                            <i className="fas fa-id-badge fa-2x text-gray-300"></i>
                        </div>
                    </div>
                </Card.Body>
            </Card>
        </Col>
        }
    </>
    );
};
        

