import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface IAuthToken {
    isAuthenticated?:boolean,
    jwtToken: string;
    expiryDate: any;
    errorMessage?:string;
    user?: any,
    pid?: any
}

const initialState: IAuthToken = {
    jwtToken: '',
    expiryDate: undefined,
    errorMessage:'',
    isAuthenticated:false,
    user: '',
    pid: -1
};

const authSlice = createSlice({
    name: "auth",
    initialState,
    reducers: {
        setJWTToken: (state, _action: PayloadAction<IAuthToken>) => {
            state.jwtToken = _action.payload.jwtToken;
            state.expiryDate = _action.payload.expiryDate;
            state.user = _action.payload.user;
            localStorage.setItem('token', state.jwtToken);
            localStorage.setItem('expire', state.expiryDate);
            localStorage.setItem('user', state.user.descrizione)
            localStorage.setItem('pid', state.user.id)
            state.isAuthenticated = true;
           
        },
        removeJWTToken: (state) => {
            localStorage.clear();
            state.jwtToken = '';
            state.expiryDate=undefined;
            state.isAuthenticated = false;
            state.user = undefined;
            state.pid = -1
        },
        setError: (state, _action: PayloadAction<string>) => {
            state.errorMessage = _action.payload;
        },
    },
});

export const { setJWTToken, removeJWTToken,setError } = authSlice.actions;

export default authSlice.reducer;

