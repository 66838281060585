import { APIService } from "services";
import { getUtenteByLogin } from "./utenteService";
import { getInstallatoreLogin } from "./installatoreService";
import { getAziendaLogin } from "./aziendaService";

export const getImpianto = async (pageNo,pageSize,search) => {
    let res;
    
    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")

    if(search.length===0) {
         
        if(tipoUtente === "UTENTE"){
            const utenteData = await getUtenteByLogin(idUtente);

            idUtente = utenteData.data.document.records[0].id

            res = await getImpiantiUtente(idUtente,pageNo,pageSize);
        }
        if(tipoUtente === "ADMIN")res = await getAllImpianto(pageNo,pageSize);
        if(tipoUtente === "INSTALLATORE"){
            const utenteData = await getInstallatoreLogin(idUtente,pageNo, pageSize).then(result => {return result});
            idUtente = utenteData.data.document.records[0].azienda
            res = await getImpiantiInstallatore(idUtente, pageNo, pageSize).then(result => {return result});
        }
        if(tipoUtente === "AZIENDA"){
            const utenteData = await getAziendaLogin(idUtente,pageNo, pageSize).then(result => {return result});
            idUtente = utenteData.data.document.records[0].id
            res = await getImpiantiAzienda(idUtente, pageNo, pageSize).then(result => {return result});
        }

    }
    else{
        try {
            if(tipoUtente === "UTENTE")res = await getImpiantiUtente(idUtente,pageNo,pageSize);
            if(tipoUtente === "ADMIN")res = await searchImpianto(search,pageNo,pageSize);
            if(tipoUtente === "INSTALLATORE"){
                const utenteData = await getInstallatoreLogin(idUtente,pageNo, pageSize).then(result => {return result});
                idUtente = utenteData.data.document.records[0].azienda
                res = await getImpiantiInstallatore(idUtente, pageNo, pageSize).then(result => {return result});
            }
            if(tipoUtente === "AZIENDA"){
                //const utenteData = await getAziendaLogin(idUtente,pageNo, pageSize).then(result => {return result});
                //idUtente = utenteData.data.document.records[0].id
                res = await getImpiantiAzienda(idUtente, pageNo, pageSize).then(result => {return result});
            }
            

        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllImpianto = (pageno,pagesize) => {
return APIService.api().get(`/impianto/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getImpiantiUtente = (utente, pageno,pagesize) => {
return APIService.api().get(`/impianto/read_impianti_utente.php?utente=${utente}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getImpiantiInstallatore = (id, pageno,pagesize) => {
return APIService.api().get(`/impianto/read_by_installatore.php?pid=${id}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getImpiantiAzienda = (id, pageno,pagesize) => {
return APIService.api().get(`/impianto/read_by_azienda.php?pid=${id}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneImpianto = (id) => {
return APIService.api().get(`/impianto/read_one.php?id=${id}`)
}
export const searchImpianto = (key,pageno,pagesize) => {
return APIService.api().get(`/impianto/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addImpianto = (data) => {
return APIService.api().post(`/impianto/create.php`,data)
}
export const updateImpianto = (data) => {
return APIService.api().post(`/impianto/update.php`,data)
}
export const deleteImpianto = (id) => {
return APIService.api().post(`/impianto/delete.php`,{id:id})
}
