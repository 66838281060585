import { combineReducers } from "redux";

import template from "redux/slices/template";
import authToken from "redux/slices/auth";

import login from "redux/slices/login";
import utente from "redux/slices/utente";
import tipo_login from "redux/slices/tipo_login";
import tipo_intervento from "redux/slices/tipo_intervento";
import tipo_impianto from "redux/slices/tipo_impianto";
import scheda_intervento from "redux/slices/scheda_intervento";
import installatore from "redux/slices/installatore";
import impianto from "redux/slices/impianto";
import azienda from "redux/slices/azienda";


const rootReducer = combineReducers({ template,authToken,login,utente,tipo_login,tipo_intervento,tipo_impianto,scheda_intervento,installatore,impianto,azienda });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

