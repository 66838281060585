import { APIService } from "services";

export const sendEmail = async (data) => {

    const response = await sendEmailNotifica(data);

    return response

}


export const sendEmailNotifica = (data) => {
    return APIService.api().post(`/notification/sendemail.php`,data)
}