import { APIService } from "services";
import { getAziendaLogin } from "./aziendaService";
import { getInstallatoreLogin } from "./installatoreService";

export const getUtente = async (pageNo,pageSize,search) => {
    let res;
    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")

    if(search.length===0) {
        if(tipoUtente === "UTENTE") res = await getUtenteByLogin(idUtente);
        if(tipoUtente === "ADMIN") res = await getAllUtente(pageNo,pageSize);
        if(tipoUtente === "INSTALLATORE") {
            const utenteData = await getInstallatoreLogin(idUtente,pageNo,pageSize).then(result => {return result});
            idUtente = utenteData.data.document.records[0].azienda
            res =  await getUtentiInstallatore(idUtente,pageNo,pageSize);
        }
        if(tipoUtente === "AZIENDA") {
            const utenteData = await getAziendaLogin(idUtente,pageNo,pageSize).then(result => {return result});
            idUtente = utenteData.data.document.records[0].id
            res =  await getUtentiAzienda(idUtente,pageNo,pageSize).then(result => {return result});
        }
    }
    else{
        try {
            res = await searchUtente(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}

export const getUtentiInstallatore = (id,pageno,pagesize) => {
const res = APIService.api().get(`/utente/read_utente_by_installatore.php?pid=${id}&pageno=${pageno}&pagesize=${pagesize}`)
return res; 
}
export const getUtentiAzienda = (id,pageno,pagesize) => {
const res = APIService.api().get(`/utente/read_utente_by_azienda.php?pid=${id}&pageno=${pageno}&pagesize=${pagesize}`)
return res; 
}
export const getUtenteByLogin = (id) => {
return APIService.api().get(`/utente/read_utente_by_login.php?pid=${id}`)
}
export const getAllUtente = (pageno,pagesize) => {
return APIService.api().get(`/utente/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneUtente = (id) => {
return APIService.api().get(`/utente/read_one.php?id=${id}`)
}
export const searchUtente = (key,pageno,pagesize) => {
return APIService.api().get(`/utente/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addUtente = (data) => {
return APIService.api().post(`/utente/create.php`,data)
}
export const updateUtente = (data) => {
return APIService.api().post(`/utente/update.php`,data)
}
export const deleteUtente = (id) => {
return APIService.api().post(`/utente/delete.php`,{id:id})
}
