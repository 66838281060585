import { APIService } from "services";
import { getUtenteByLogin } from "./utenteService";
import { getInstallatoreLogin } from "./installatoreService";
import { getAziendaLogin } from "./aziendaService";



// export const getFileArchivioScheda = async (archivio) => {
//     let res;
//     const tipoUtente = localStorage.getItem("user")
//     let idUtente = localStorage.getItem("pid")
    
//     if(tipoUtente === "UTENTE"){ 
//         const utenteData = await getUtenteByLogin(idUtente);
//         if (utenteData.data.document == null && utenteData.data.document.records.length > 0){
//             idUtente = utenteData.data.document.records[0].id
//         }else{
//             const utenteData = await getUtenteByLogin(idUtente);
//             idUtente = utenteData.data.document.records[0].id
//         }
//     }
//     //if(tipoUtente === "ADMIN")res = await getAllScheda_Intervento(pageNo,pageSize);
//     if(tipoUtente === "INSTALLATORE"){

//         const installatoreData = await getInstallatoreLogin(idUtente,1,10000);
//         if (installatoreData.data.document == null && installatoreData.data.document.records.length > 0){
//             idUtente = installatoreData.data.document.records[0].id
//         }else{
//             const installatoreData = await getInstallatoreLogin(idUtente,1,10000);
//             idUtente = installatoreData.data.document.records[0].id
//         }
//     }
//     if(tipoUtente === "AZIENDA"){

//         const aziendaData = await getAziendaLogin(idUtente,1,10000);
//         if (aziendaData.data.document == null && aziendaData.data.document.records.length > 0){
//             idUtente = aziendaData.data.document.records[0].id
//         }else{
//             const installatoreData = await getAziendaLogin(idUtente,1,10000);
//             idUtente = installatoreData.data.document.records[0].id
//         }

//         res = getFileArchivio(archivio, idUtente)
//     }



    

//     if(res && res.data && res.data.document){
//     return res.data.document;
//     }else{
//     return { records:[], totalCount:0 }
//     }
    
// }

export const getFileArchivioScheda = async (service, user) => {

    const response = await getFileArchivio(service, user);

    return response

}

export const getViewFileArchivioScheda = async (archivio, file, user) => {
    return await getViewFileArchivio(archivio, file, user);
}

export const getFileArchivio = (service, user) => {
    let responnse = APIService.api().get(`/visualizzadati/elenco_file_archivio.php?service=${service}&actionreq=${user}`).then(result => {return result})
    return responnse;//APIService.api().get(`/visualizzadati/elenco_file_archivio.php?service=${service}&actionreq=${user}`)
}

export const getViewFileArchivio = (archivio, file, user) => {
    return APIService.api().get(`/visualizzadati/visualizza_documenti.php?service=${archivio}&name=${file}&actionreq=${user}`)
    }