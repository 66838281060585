import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { resetImpiantoToInit, resetTipo_InterventoToInit, resetUtenteToInit, setImpiantoList, setScheda_InterventoMessage, setTipo_InterventoList, setTipo_InterventoMessage, setUtenteList } from "redux/actions";
import { resetAziendaToInit, setAziendaList } from "redux/actions";
import { resetInstallatoreToInit, setInstallatoreList } from "redux/actions";
import { resetTipo_ImpiantoToInit, setTipo_ImpiantoList } from "redux/actions";

import { getAzienda } from "services/aziendaService";
import { getInstallatore } from "services/installatoreService";
import { getTipo_Impianto } from "services/tipo_impiantoService";

import { useAppDispatch } from "redux/store";
import { addScheda_Intervento, updateScheda_Intervento } from "services/scheda_interventoService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { getUtente } from "services/utenteService";
import { getImpiantiUtente, getImpianto } from "services/impiantoService";
import { uploadFileService, uploadImageService } from "services/fileUploadService";
import { getTipo_Intervento } from "services/tipo_interventoService";
import { getFileArchivio, getFileArchivioScheda, getViewFileArchivio, getViewFileArchivioScheda } from "services/documentiService";


type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const Scheda_InterventoForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',tipointevento:'',data:'',descrizione:'',indirizzo:'',foto:'',tiporinnovo:'',costo:'',azienda:'',installatore:'',impianto:'',utente:''};
    const initialValue = action === 'edit' ? row : iValue;
    const aziendaData = useSelector((state: RootState) => state.azienda);
const installatoreData = useSelector((state: RootState) => state.installatore);
const tipo_impiantoData = useSelector((state: RootState) => state.tipo_impianto);
const tipo_interventoData = useSelector((state: RootState) => state.tipo_intervento);
const utenteData = useSelector((state: RootState) => state.utente);
const impiantoData = useSelector((state: RootState) => state.impianto);

const [message, setMessage] = useState('')
const [messageError, setMessageError] = useState('')
const [nomeArchivio, setNomeArchivio] = useState('')
const [listaFile, setListaFile] = useState(null)

    useEffect(() => {
if (aziendaData && aziendaData.list && aziendaData.list.length === 0) {
            dispatch(resetAziendaToInit());
            getAzienda(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setAziendaList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setScheda_InterventoMessage("No Record Found For Azienda"));
                }
              })
        }
if (installatoreData && installatoreData.list && installatoreData.list.length === 0) {
            dispatch(resetInstallatoreToInit());
            getInstallatore(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setInstallatoreList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setScheda_InterventoMessage("No Record Found For Installatore"));
                }
              })
        }
if (tipo_impiantoData && tipo_impiantoData.list && tipo_impiantoData.list.length === 0) {
            dispatch(resetTipo_ImpiantoToInit());
            getTipo_Impianto(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setTipo_ImpiantoList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setScheda_InterventoMessage("No Record Found For Tipo_Impianto"));
                }
              })
        }
if (impiantoData && impiantoData.list && impiantoData.list.length === 0) {
    dispatch(resetImpiantoToInit());
    getImpianto(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
        if (response && response.records) {
            dispatch(setImpiantoList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        } else {
            dispatch(setScheda_InterventoMessage("No Record Found For Impianto"));
        }
        })
}
if (utenteData && utenteData.list && utenteData.list.length === 0) {
    dispatch(resetUtenteToInit());
    getUtente(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
        if (response && response.records) {
            dispatch(setUtenteList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        } else {
            dispatch(setScheda_InterventoMessage("No Record Found For Utente"));
        }
        })
}
  if (tipo_interventoData && tipo_interventoData.list && tipo_interventoData.list.length === 0) {
    dispatch(resetTipo_InterventoToInit());
    getTipo_Intervento(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
        if (response && response.records) {
          dispatch(setTipo_InterventoList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
        } else {
          dispatch(setTipo_InterventoMessage("No Record Found"));
        }
      })
}

if(listaFile === null){
    console.log(row);
    if(action != "add"){
        getFileArchivioScheda(row.foto, row.azienda).then((risposta) => {
            setListaFile(risposta.data.response);
            handleListaFile(risposta.data.response);
            setNomeArchivio(row.foto)
            console.log(risposta.data.response);
            
        }).catch(error => {
            console.log(error);
            //setIsLoading(false);
    });
    }
}

})


const handleChangeUtente = (e, formInfo) => {
    console.log(formInfo)
    formik.handleChange(e);
    if(formik.values != null && formInfo !=''){
        let utenteSelezione = formInfo
        getImpiantiUtente(utenteSelezione, Constant.defaultPageNumber, Constant.defaultDropdownPageSize).then((response) => {
            if (response && response.data && response.data.document && response.data.document.records) {
              dispatch(setImpiantoList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.data.document.records, totalCount: response.data.count, searchKey: '' }));
            } else {
              dispatch(setScheda_InterventoMessage("No Record Found For Impianto"));
            }
        })
    }
}

    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateScheda_Intervento(values);
                if (response) {
                    dispatch(setScheda_InterventoMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setScheda_InterventoMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                values.foto = nomeArchivio
                const response = await addScheda_Intervento(values);
                if (response) {
                    dispatch(setScheda_InterventoMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setScheda_InterventoMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           tipointevento: yup.number().nullable(),
data: yup.date().nullable(),
descrizione: yup.string().nullable(),
indirizzo: yup.string().required('indirizzo is required'),
foto: yup.string().nullable(),
tiporinnovo: yup.number().nullable(),
costo: yup.number().nullable(),
azienda: yup.string().required('azienda is required'),
installatore: yup.string().required('installatore is required'),
impianto: yup.string().required('impianto is required'),
utente: yup.string().required('utente è obbligatorio')

        }),
    });

    const formikImage = useFormik({
        initialValues: { image: [] },
        onSubmit: async (values) => {
            setMessage('');
            setMessageError('')
            var formData = new FormData();
            let count = 1;
            let dataArchivio = nomeArchivio
            if(nomeArchivio == null || nomeArchivio === '') dataArchivio = new Date().valueOf().toString();
            formData.set("utente", aziendaData.list[0].id.toString())
            //setNomeArchivio(dataArchivio)
            formData.set("archivio", dataArchivio)
            values.image.map((images) => {
                    formData.append("image" + count, images);
                    count++
                }
            )
            let nomeArchivioFileConNumero = count.toString()+ "_" +dataArchivio 
            setNomeArchivio(dataArchivio)
            uploadImageService(formData).then((res: any) => {
                if (res && res.data && res.data.document && res.data.status === 'success') {
                    setMessage('file Caricati con Successo : ' + res.data.document);
                    //formikImage.resetForm();
                }else{
                    setMessageError("Attenzione: " + res.data.document);
                    //formikFile.resetForm();
                }
            })
        },
        validationSchema: yup.object({
            image: yup.array().min(1, "Select at least 1 image")
        }),
    });

    const formikFile = useFormik({
        initialValues: { fileData: [] },
        onSubmit: async (values) => {
            var formData = new FormData();
            formData.append("file", values.fileData[0]);
            uploadFileService(formData).then((res: any) => {
                if (res && res.data && res.data.document) {
                    res.data.document.map((item, i) => {
                        return setMessage('File Successfully Uploaded : ' + item);
                        })
                    formikFile.resetForm();
                }else{
                    setMessage('Failed to upload file');
                    formikFile.resetForm();
                }
            })
        },
        validationSchema: yup.object({
            fileData: yup.array().min(1, "Select at least 1 file")
        }),
    });


    const handleListaFile = (listaFile) => {
        return(
            listaFile.map((images) => {
                return(
                    <p>{images}</p>
                )
            })
        )
    }

    const handleFileClick = (images) => {
        console.log(images);
        console.log(row);

        handleViewFile(row.foto,images, row.azienda);
    }

    const base64toBlobPDF = (data: string) => {
        const base64WithoutPrefix = data;

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'application/pdf' });
    };

    const base64toBlobImage = (data: string, tipo: string) => {
        const base64WithoutPrefix = data;

        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
            out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: 'image/'+ '*' });
    };

    const handleViewFile = (archivio, file, utente) => {
        let action = 1; 
        if(file.includes(".zip")){
            // action = 1;
            // getViewFileArchivioScheda(archivio, file, utente).then(response => {
            //     const blob = base64toBlobZIP(response.data.response);
            //     const fileURL = URL.createObjectURL(blob);
            //     window.open(fileURL, "_blank");
            //     setIsLoading(false);
            // })
            //     .catch(error => {
            //         //console.log(error);
            //         setIsLoading(false);
            // });
        }else if(file.includes(".pdf")){
            getViewFileArchivioScheda(archivio, file, utente).then(response => {
                const blob = base64toBlobPDF(response.data.response);
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL, "_blank");
                //setIsLoading(false);
            })
                .catch(error => {
                    //console.log(error);
                    //setIsLoading(false);
            });
        }else if(file.includes(".jpg")){
            getViewFileArchivioScheda(archivio, file, utente).then(response => {
                const blob = base64toBlobImage(response.data.response, "jpg");
                const fileURL = URL.createObjectURL(blob);
                window.open(fileURL, "_blank");
                //setIsLoading(false);
            })
                .catch(error => {
                    //console.log(error);
                    //setIsLoading(false);
            });
        }else if(file.includes(".jpeg")){
                getViewFileArchivioScheda(archivio, file, utente).then(response => {
                    const blob = base64toBlobImage(response.data.response, "jpeg");
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, "_blank");
                    //setIsLoading(false);
                })
                    .catch(error => {
                        //console.log(error);
                        //setIsLoading(false);
                });
            }else if(file.includes(".png")){
                getViewFileArchivioScheda(archivio, file, utente).then(response => {
                    const blob = base64toBlobImage(response.data.response, "png");
                    const fileURL = URL.createObjectURL(blob);
                    window.open(fileURL, "_blank");
                    //setIsLoading(false);
                })
                    .catch(error => {
                        //console.log(error);
                        //setIsLoading(false);
                });
        }else{
            //setIsLoading(false);
            //handleNavigateElenco(row)
        }

    }



    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <>
                    <p className="no-margin-bottom">
                        <a className="btn-icon-split" onClick={() => hideShowForm(false)}>
                            <p className="no-margin-bottom">
                                <span className="">
                                    <i className="fas fa-arrow-left"></i>
                                </span>
                                <span className="text">Lista</span>
                            </p>
                        </a>
                    </p>
                    <p className="m-0 font-weight-bold text-primary text-capitalize">Nuova Scheda Intervento</p>
                </>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                <Form.Group>
<label className="form -control-label">tipointevento</label>
<Form.Control as="select"  name="tipointevento" className="form-control" value={formik.values.tipointevento}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.tipointevento && !!formik.errors.tipointevento}
isValid ={!!formik.touched.tipointevento && !formik.errors.tipointevento}
>
<option value={0}>Select Tipo Intervento </option> 
{
tipo_interventoData.list.map((item, i) => {
return <option value={item.id} key={`tipo_intervento-${i}`}>{item.descrizione}</option>
})}
</Form.Control>
{
    formik.errors.tipointevento && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.tipointevento}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">data</label>
<Form.Control type="date" name="data" className="form-control" value={formik.values.data}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.data && !!formik.errors.data}
isValid ={!!formik.touched.data && !formik.errors.data}
></Form.Control>
{
    formik.errors.data && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.data}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">descrizione</label>
<Form.Control as="textarea" rows={5} name="descrizione" className="form-control" value={formik.values.descrizione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.descrizione && !!formik.errors.descrizione}
isValid ={!!formik.touched.descrizione && !formik.errors.descrizione}
></Form.Control>
{
    formik.errors.descrizione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.descrizione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">Indirizzo Completo (Via, numero civico, cap e città)</label>
<Form.Control type="text" name="indirizzo" className="form-control" value={formik.values.indirizzo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.indirizzo && !!formik.errors.indirizzo}
isValid ={!!formik.touched.indirizzo && !formik.errors.indirizzo}
></Form.Control>
{
    formik.errors.indirizzo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.indirizzo}
    </Form.Control.Feedback>
)}
</Form.Group>
{/* <Form.Group>
<label className="form -control-label">Nome Archivio Foto</label>
<Form.Control type="text" name="foto" className="form-control" value={formik.values.foto}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.foto && !!formik.errors.foto}
isValid ={!!formik.touched.foto && !formik.errors.foto}
></Form.Control>
{
    formik.errors.foto && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.foto}
    </Form.Control.Feedback>
)}
</Form.Group> */}




<div className="d-flex flex-column" >
                    <Row>
                        
                        <Col md={6}>
                            <Card>
                                <Card.Header>Carica File</Card.Header>
                                <Card.Body>
                                <Form.Group controlId="formFile" className="mb-3">
                                            <Form.Label>Carica png | jpg | jpeg | pdf files</Form.Label>
                                            <Form.Control type="file" multiple
                                                onChange={(event: React.ChangeEvent) => {
                                                    const imgFile = event.target as HTMLInputElement;
                                                    if (imgFile.files && imgFile.files[0]) {
                                                        formikImage.setFieldValue("image", Array.from(imgFile.files));
                                                        //setNomeArchivio("scheda1")
                                                    }
                                                }}
                                                onBlur={formikImage.handleBlur}
                                                isInvalid={!!formikImage.touched.image && !!formikImage.errors.image}
                                                isValid={!!formikImage.touched.image && !formikImage.errors.image}
                                            />
                                            {
                                                formikImage.errors.image && (
                                                    <Form.Control.Feedback type="invalid">
                                                        {formikImage.errors.image}
                                                    </Form.Control.Feedback>
                                                )}
                                        </Form.Group>
                                    <Form onClick={formikImage.handleSubmit}>
                                        
                                        <Form.Group>
                                            <Button type="button" className="float-right" variant="primary" >Carica</Button>
                                        </Form.Group>
                                    </Form>


                                </Card.Body>
                            </Card>
                        </Col>
                        <Col md={6}>
                            <Card className="h100">
                                <Card.Header>File Caricati</Card.Header>
                                <Card.Body>
                                <Row>    
                                    {message ? <Col md={12}><Alert variant="info">{message}</Alert></Col> : null}
                                    {messageError ? <Col md={12}><Alert variant="alert" className="alert-error">{messageError}</Alert></Col> : null}
                                    {action == "edit" && listaFile != null &&
                                        <Col md={12}>
                                            {listaFile.map((images) => {
                                                return(
                                                    <Button key={images} variant="link" className="collapse-item pt-0" onClick={() => handleFileClick(images)}>
                                                    <i className="fas fa-fw fa-file"></i><span>{images}</span></Button>
                                                )}
                                            )}
                                        </Col>
                                        }
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>




<Form.Group>
<label className="form -control-label">Tipo Rinnovo</label>
<Form.Control as="select"  name="tiporinnovo" className="form-control" value={formik.values.tiporinnovo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.tiporinnovo && !!formik.errors.tiporinnovo}
isValid ={!!formik.touched.tiporinnovo && !formik.errors.tiporinnovo}
>
<option value={-1}>Select Tipo Rinnovo </option>
<option value={0} key={`tipo_rinnovo-0`}>Rinnovo non previsto</option>
<option value={1} key={`tipo_rinnovo-1`}>Rinnovo semestrale</option>
<option value={2} key={`tipo_rinnovo-2`}>Rinnovo annuale</option>
<option value={3} key={`tipo_rinnovo-3`}>Rinnovo biennale</option>
</Form.Control>
{
    formik.errors.tiporinnovo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.tiporinnovo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">costo</label>
<Form.Control type="text" name="costo" className="form-control" value={formik.values.costo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.costo && !!formik.errors.costo}
isValid ={!!formik.touched.costo && !formik.errors.costo}
></Form.Control>
{
    formik.errors.costo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.costo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">utente</label>
<Form.Control as="select"  name="utente" className="form-control" value={formik.values.utente}
onChange={e => { handleChangeUtente(e,  e.target.value)}} //{formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.utente && !!formik.errors.utente}
isValid ={!!formik.touched.utente && !formik.errors.utente}
>
<option value={0}>Select Utente </option> 
{
utenteData.list.map((item, i) => {
return <option value={item.id} key={`impianto-${i}`}>{item.nome} {item.cognome} - {item.codicefiscale}</option>
})}
</Form.Control>
{
    formik.errors.utente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.utente}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">azienda</label>
<Form.Control as="select"  name="azienda" className="form-control" value={formik.values.azienda}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.azienda && !!formik.errors.azienda}
isValid ={!!formik.touched.azienda && !formik.errors.azienda}
>
<option value={0}>Select Azienda </option> 
{
aziendaData.list.map((item, i) => {
return <option value={item.id} key={`azienda-${i}`}>{item.denominazione}</option>
})}
</Form.Control>
{
    formik.errors.azienda && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.azienda}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">installatore</label>
<Form.Control as="select"  name="installatore" className="form-control" value={formik.values.installatore}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.installatore && !!formik.errors.installatore}
isValid ={!!formik.touched.installatore && !formik.errors.installatore}
>
<option value={0}>Select Installatore </option> 
{
installatoreData.list.map((item, i) => {
return <option value={item.id} key={`installatore-${i}`}>{item.denominazione}</option>
})}
</Form.Control>
{
    formik.errors.installatore && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.installatore}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">impianto</label>
<Form.Control as="select"  name="impianto" className="form-control" value={formik.values.impianto}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.impianto && !!formik.errors.impianto}
isValid ={!!formik.touched.impianto && !formik.errors.impianto}
>
<option value={0}>Seleziona Impianto </option> 
{
impiantoData.list.map((item, i) => {
return <option value={item.id} key={`tipo_impianto-${i}`}>{item.nomeimpianto}</option>
})}
</Form.Control>
{
    formik.errors.impianto && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.impianto}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

