import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { useAppDispatch } from "redux/store";
import { resetScheda_InterventoToInit, setScheda_InterventoMessage } from "redux/actions";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Constant } from "template/Constant";
import ConfirmationModal from "template/ConfirmationModal";
import { deleteScheda_Intervento } from "services/scheda_interventoService";
import DetailElencoImpreseModal from "template/DetailSchedaInterventoModal";
import { getOneTipo_Intervento } from "services/tipo_interventoService";
import { getOneAzienda } from "services/aziendaService";
import { getOneImpianto } from "services/impiantoService";
import { getOneInstallatore } from "services/installatoreService";
import { getOneUtente } from "services/utenteService";
import { getFileArchivioScheda } from "services/documentiService";
import ReactPaginate from "react-paginate";
import { convertData } from "utils/utility";

type Props = {
    hideShowForm: (action) => void;
    handleRowEdit: (row) => void;
    getData: (page, pageSize, searchKey) => void;
};
export const Scheda_InterventoTable: React.FC<Props> = ({ hideShowForm, handleRowEdit, getData }) => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState('');
    const [showDelete, setShowDelete] = useState(false);
    const [rowData, setRowData] = useState(undefined);
    const [row, setRow] = useState(undefined);
    const [tipoIntervento, setTipoIntervento] = useState(undefined)
    const [tipoRinnovo, setTipoRinnovo] =  useState(undefined)
    const [azienda, setAzienda] = useState(undefined)
    const [impianto, setImpianto] = useState(undefined)
    const [installatore, setInstallatore] = useState(undefined)
    const [utente, setUtente] = useState(undefined)
    const [listaFile, setListaFile] = useState([])
    const [tipologiaUtente, setTipologiaUtente] = useState(undefined)
    const [showDetailElenco, setShowDetailElenco] = useState(false);

    const [postsPerPage] = useState(10);
    const [offset, setOffset] = useState(1);
    const [posts, setAllPosts] = useState([]);
    const [pageCount, setPageCount] = useState(0)

    const elencoImprese: any [] = []
    const [lstElencoImprese, setListElencoImprese] = useState(elencoImprese);
    const [lstElencoImpreseCompleto, setListElencoImpreseCompleto] = useState(elencoImprese);

    const [countImprese, setcountImprese] = useState(elencoImprese.length);
    
    const rData = useSelector((state: RootState) => state.scheda_intervento);
    const tipo_interventoData = useSelector((state: RootState) => state.tipo_intervento);
    const handleSearch = () => {
        if (search.length > 0) {
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, search);
        }
    }
    const handlePerRowsChange = async (newPerPage, page) => {
        await getData(page, newPerPage, '');
    }
    const handlePageChange = (page) => {
        getData(page, rData.pageSize, '');
    };
    const handleRowDeleteClick = (row) => {
        setRowData(row);
        setShowDelete(true);
    }
    useEffect(() => {
        if (rData && rData.list && rData.list.length === 0) {
            dispatch(resetScheda_InterventoToInit());
            getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
            
        }else{
            getAllPosts(rData.list);
            setListElencoImprese(rData.list)
        }
    },[rData.list, rData.list.length,offset])
    const handleReset = () => {
        setSearch('');
        dispatch(resetScheda_InterventoToInit());
        getData(Constant.defaultPageNumber, rData.pageSize, '');
    }
    const handleServerDelete = async () => {
        if (rowData) {
            const response = await deleteScheda_Intervento(rowData.id);
            if (response) {
                dispatch(resetScheda_InterventoToInit());
                dispatch(setScheda_InterventoMessage("Scheda Eliminata con Successo"));
                getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                setShowDelete(false);
            } else {
                dispatch(setScheda_InterventoMessage("Scheda non Eliminata si è verificato un errore"));
            }
        }
    }

    const handleRowSelection = (row) => {
        console.log(row); // Row Selection Functionality can be written here
    }
    const handleAddButtonClick = () => {
        dispatch(setScheda_InterventoMessage(''));
        hideShowForm('add');
    }

    const handleColumns = () => {
        const tipoUtente = localStorage.getItem("user")

        if(tipoUtente === "UTENTE"){
            return columns_utente
        }else{
            return columns_generale
        }

    }

    const handlePageClick = (event) => {
        const selectedPage = event.selected;
        setOffset(selectedPage + 1)
    };

    const getAllPosts = async (elenco_input) => {
        let res = rData.list//rDataElenco
        if(elenco_input != null && elenco_input.length > 0){
            res = elenco_input
        }
        const data = res
        let slice = null;
        if(res.length < 11){
             //res.list;
            slice = data
        }else{
            //const data = res //res.list;
            //const offset_c = offset + 10;
            //const pageCount_tmp = pageCount;
            if(offset > 1){
                slice = data.slice((offset - 1) * 10 , (((offset - 1) * 10) + postsPerPage) -1)
            }else{
                slice = data.slice(offset - 1 , offset - 1 + postsPerPage)
            }
            
        }

        // const data = res //res.list;
        // const slice = data.slice(offset - 1 , offset - 1 + postsPerPage)
      
        // For displaying Data
        const postData = getPostData(slice)
      
        // Using Hooks to set value
        setAllPosts(postData)
        setPageCount(Math.ceil(data.length / postsPerPage))

        setcountImprese(res.length)
        
        //setListElencoImprese(lstElencoImprese)
    }

    const borderColorInfo = (row) => {
        //if(row["stato-impresa-attivita"] !== 'Registrata Attiva'){
          //  return "col-sm-12 riga-trova-impresa border-left-8-red background-row-white shadow";
        //}else{
            return "col-sm-12 riga-trova-impresa border-left-8-green background-row-white shadow";
        //}
    }

      const filterBySearch = (event) => {
        const query = event//event.target.value;
        let query_stato = query
        //if(query_stato === 'attiva') query_stato = "Registrata Attiva"
        // Create copy of item list
        var updatedList = lstElencoImprese //lstElencoImprese //[...lstElencoImprese];
        // Include all elements which includes the search query
        updatedList = updatedList.filter((item) => {
            if(
            (item.data != null && item.data.toLowerCase().indexOf(query.toLowerCase()) !== -1) || 
            (item.denominazioneinstallatore != null && item.denominazioneinstallatore.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
            (item.tiporinnovodesc != null && item.tiporinnovodesc.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
            (item.nomeimpianto != null && item.nomeimpianto.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
            (item.utcodicefiscale != null && item.utcodicefiscale.toLowerCase().indexOf(query.toLowerCase()) !== -1)  ||
            (item.utnome != null && item.utnome.toLowerCase().indexOf(query.toLowerCase()) !== -1)  ||
            (item.utcognome != null && item.utcognome.toLowerCase().indexOf(query.toLowerCase()) !== -1)  ||
            (item.indrizzo != null && item.indrizzo.toLowerCase().indexOf(query.toLowerCase()) !== -1) 
            ){
                return item
            }
        });
        // Trigger render with updated values
        setListElencoImprese(updatedList)
        getAllPosts(updatedList)
    };

    const handleSearchButton = () => {

        filterBySearch(search)
    
    }

    const handleRowDettaglio = (row) => {

        // if(row.stato_attivita === 'Cancellata'){
        //     return(<Button variant="link" className="btn-sm" onClick={() => handleRowDetail(row)}><i className="fa fa-search-plus icon-size-normal color-icon-notfound"></i></Button>);
        // }else{
            
            return(<Button variant="link" className="btn-sm" onClick={() => handleRowDetail(row)}><i className="fa fa-search-plus icon-size-normal"></i></Button>);
        //}
      }

    const handleRowEditAction = (row) => {

        return(<Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>
            <i className="fa fa-pencil icon-size-normal"></i></Button>);
    }
    const handleRowDeleteAction = (row) => {

        return(<Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>
            <i className="fa fa-trash icon-size-normal"></i></Button>);
    }

    const getPostData = (data) => {
        return (
          data.map(item => <div className="" key={item.id}>
            <div className={borderColorInfo(item.anagraficaimpresa)}>
                            <div className="row">
                                {/* <div className="col-sm-1 center-div-block">
                                    {handleRowDettaglio(item)}
                                </div> */}
                                <div className="col-sm-9">
                                    <div className="row">
                                        <div className="col-sm-6"><p className="no-margin-bottom">Codice:</p><p className="no-margin-bottom"><strong>{"Scheda_"+item.id}</strong></p></div>
                                        <div className="col-sm-6"><p className="no-margin-bottom">Data:</p><p className="no-margin-bottom"><strong>{convertData(item.data)}</strong></p></div>
                                        <div className="col-sm-6"><p className="no-margin-bottom">Installatore:</p><p className="no-margin-bottom"><strong>{item.denominazioneinstallatore}</strong></p></div>
                                        <div className="col-sm-6"><p className="no-margin-bottom">Rinnovo:</p><p className="no-margin-bottom"><strong>{item.tiporinnovodesc}</strong></p></div>
                                        <div className="col-sm-6"><p className="no-margin-bottom">Cliente:</p><p className="no-margin-bottom"><strong>{item.utcognome + " " + item.utnome}</strong></p></div>
                                        <div className="col-sm-6"><p className="no-margin-bottom">Codice Fiscale:</p><p className="no-margin-bottom"><strong>{item.utcodicefiscale}</strong></p></div>
                                        <div className="col-sm-12"><p className="no-margin-bottom">Impianto:</p><p className="no-margin-bottom"><strong>{item.nomeimpianto}</strong></p></div>
                                        <div className="col-sm-12"><p className="no-margin-bottom">Indirizzo:</p><p className=""><strong>{item.indirizzo}</strong></p></div>
                                    </div>
                                </div>
                                <div className="col-sm-3 center-div-block">
                                    {handleRowDettaglio(item)} {handleRowEditAction(item)} {handleRowDeleteAction(item)}
                                </div>  
                                {/* <div className="col-sm-1 center-div-block">
                                    {handleRowDeleteAction(item)}
                                </div>     */}
                            </div>
                        </div>
          </div>)
        )
      
      }

    const handleRowDetail = (rowData) => {
        const tipoUtente = localStorage.getItem("user")
        setTipologiaUtente(tipoUtente)
        setRow(rowData);
        setShowDetailElenco(true);

        getFileArchivioScheda(rowData.foto, rowData.azienda).then((risposta) => {
            setListaFile(risposta.data.response);
            if(risposta.data.response == null || risposta.data.response.length() == 0){
                setListaFile([]);
            }
            
        }).catch(error => {
            console.log(error);
            //setIsLoading(false);
        });

        getOneTipo_Intervento(rowData.tipointevento).then((response) => {
            if (response && response.data) {
                setTipoIntervento(response.data.document.descrizione)
            } else {
                setTipoIntervento("in caricamento")
            }
        })

        if(rowData.tiporinnovo == '0' ) setTipoRinnovo("Rinnovo non previsto")
        if(rowData.tiporinnovo == '1' ) setTipoRinnovo("Rinnovo semestrale")
        if(rowData.tiporinnovo == '2' ) setTipoRinnovo("Rinnovo annuale")
        if(rowData.tiporinnovo == '3' ) setTipoRinnovo("Rinnovo biennale")

        getOneAzienda(rowData.azienda).then((response) => {
            if (response && response.data) {
                setAzienda(response.data.document.records[0].denominazione)
            } else {
                setAzienda("in caricamento")
            }
        })

        getOneImpianto(rowData.impianto).then((response) => {
            if (response && response.data) {
                setImpianto(response.data.document.nomeimpianto)
            } else {
                setImpianto("in caricamento")
            }
        })

        getOneInstallatore(rowData.installatore).then((response) => {
            if (response && response.data) {
                setInstallatore(response.data.document.denominazione + " - " + response.data.document.cognome + " " +  response.data.document.nome )
            } else {
                setInstallatore("in caricamento")
            }
        })



        if(tipoUtente !="UTENTE"){
            getOneUtente(rowData.utente).then((response) => {
                if (response && response.data) {
                    setUtente(response.data.document.codicefiscale + " - " + response.data.document.cognome + " " +  response.data.document.nome )
                } else {
                    setUtente("in caricamento")
                }
            })
        }
      }

    const columns_utente = [
        {name: 'tipointevento', selector: row => row.tipointevento, sortable: true},
        {name: 'data', selector: row => row.data, sortable: true},
        {name: 'descrizione', selector: row => row.descrizione, sortable: true},
        {name: 'indirizzo', selector: row => row.indirizzo, sortable: true},
        {name: 'foto', selector: row => row.foto, sortable: true},
        {name: 'tiporinnovo', selector: row => row.tiporinnovo, sortable: true},
        {name: 'costo', selector: row => row.costo, sortable: true},
        // {name: 'azienda', selector: row => row.azienda, sortable: true},
        // {name: 'installatore', selector: row => row.installatore, sortable: true},
        // {name: 'impianto', selector: row => row.impianto, sortable: true},
        // {name: 'utente', selector: row => row.utente, sortable: true},

        ,{
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDetail(row)}>Dettaglio</Button>,
        },
        {
            name: '',
            button: true,
            cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
        },
    ];

    const columns_generale = [
        {name: 'id', selector: row => row.id, sortable: true},
 {name: 'tipointevento', selector: row => row.tipointevento, sortable: true},
 {name: 'data', selector: row => row.data, sortable: true},
 {name: 'descrizione', selector: row => row.descrizione, sortable: true},
 {name: 'indirizzo', selector: row => row.indirizzo, sortable: true},
 {name: 'foto', selector: row => row.foto, sortable: true},
 {name: 'tiporinnovo', selector: row => row.tiporinnovo, sortable: true},
 {name: 'costo', selector: row => row.costo, sortable: true},
 {name: 'azienda', selector: row => row.azienda, sortable: true},
 {name: 'installatore', selector: row => row.installatore, sortable: true},
 {name: 'impianto', selector: row => row.impianto, sortable: true},
 {name: 'utente', selector: row => row.utente, sortable: true},
            ,{
                name: '',
                button: true,
                cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDetail(row)}>Dettaglio</Button>,
            },
         ,{
             name: '',
             button: true,
             cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowEdit(row)}>Edit</Button>,
         },
         {
             name: '',
             button: true,
             cell: (row) => <Button variant="link" className="btn-sm" onClick={() => handleRowDeleteClick(row)}>Delete</Button>,
         },
     ];
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
                <h6 className="m-0 font-weight-bold text-primary">Scheda Intervento ({rData.totalCount})
                    <Button variant="light" className="btn-circle btn-sm ml-2" onClick={handleReset}><i className="fa fa-refresh"></i></Button>
                    <Button className="btn-icon-split float-right" onClick={handleAddButtonClick}>
                        <span className="icon text-white-50">
                            <i className="fas fa-add"></i>
                        </span>
                        {/* <span className="text">Add New</span> */}
                    </Button></h6>

            </Card.Header>
            <Card.Body>
                <Col className="ml-auto" md={3} xs={12} xl={3}>
                    <InputGroup>
                        <Form.Control
                            placeholder="Search"
                            aria-label="Search"
                            aria-describedby="basic-search"
                            onChange={(e) => setSearch(e.target.value)}
                        />
                        <Button disabled={search.length <= 2} variant="dark" className="rounded-0 rounded-right" id="button-search" onClick={handleSearchButton}>
                            Search
                        </Button>
                    </InputGroup>
                </Col>

                <Row>
                <div className="main-app">

                <ReactPaginate
                    previousLabel={"pre"}
                    nextLabel={"succ"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={pageCount}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    //subContainerClassName={"pages pagination"}
                    activeClassName={"active"} />
                    {/* Display all the posts */}
                    {posts}
                    {/* Using React Paginate */}
                 </div>
                </Row>


                {/* <div className="table-responsive">
                    <DataTable
                        selectableRows={true}
                        onSelectedRowsChange={handleRowSelection}
                        paginationPerPage={Constant.defaultPageNumber}
                        paginationRowsPerPageOptions={Constant.paginationRowsPerPageOptions}
                        columns={handleColumns()} data={rData.list}
                        onChangeRowsPerPage={handlePerRowsChange}
                        paginationTotalRows={rData.totalCount}
                        className="table table-bordered"
                        pagination
                        paginationServer
                        onChangePage={handlePageChange}></DataTable>
                </div> */}
            </Card.Body>
            <ConfirmationModal buttonNegative="Annulla" buttonPositive="Elimina" title="Conferma Elimina" show={showDelete} body={"Sei sicuro di voler eliminare questo elemento?"} onNegative={() => setShowDelete(false)} onPositive={handleServerDelete} />
            <DetailElencoImpreseModal 
            buttonNegative="Chiudi" 
            buttonPositive="Chiudi" 
            title="Dettaglio Impresa" 
            tipointervento={tipoIntervento}
            tiporinnovo={tipoRinnovo} 
            azienda={azienda}
            installatore={installatore}
            impianto={impianto}
            tipoUtente={tipologiaUtente}
            utente={utente}
            foto={listaFile}
            show={showDetailElenco} 
            body={row} 
            onNegative={() => setShowDetailElenco(false)} />
        </Card>
    );
}

