import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setInstallatoreMessage } from "redux/actions";
import { resetLoginToInit, setLoginList } from "redux/actions";
import { resetAziendaToInit, setAziendaList } from "redux/actions";

import { getLogin } from "services/loginService";
import { getAzienda } from "services/aziendaService";

import { useAppDispatch } from "redux/store";
import { addInstallatore, updateInstallatore } from "services/installatoreService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const InstallatoreForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',nome:'',cognome:'',denominazione:'',codicefiscale:'',citta:'',cap:'',indirizzo:'',telefono:'',email:'',login:'',azienda:''};
    const initialValue = action === 'edit' ? row : iValue;
    const loginData = useSelector((state: RootState) => state.login);
const aziendaData = useSelector((state: RootState) => state.azienda);

    useEffect(() => {
if (loginData && loginData.list && loginData.list.length === 0) {
            dispatch(resetLoginToInit());
            getLogin(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setLoginList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setInstallatoreMessage("No Record Found For Login"));
                }
              })
        }
if (aziendaData && aziendaData.list && aziendaData.list.length === 0) {
            dispatch(resetAziendaToInit());
            getAzienda(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setAziendaList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setInstallatoreMessage("No Record Found For Azienda"));
                }
              })
        }
})
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateInstallatore(values);
                if (response) {
                    dispatch(setInstallatoreMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setInstallatoreMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addInstallatore(values);
                if (response) {
                    dispatch(setInstallatoreMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setInstallatoreMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           nome: yup.string().required('nome is required'),
cognome: yup.string().required('cognome is required'),
denominazione: yup.string().required('denominazione is required'),
codicefiscale: yup.string().required('codicefiscale is required'),
citta: yup.string().nullable(),
cap: yup.string().nullable(),
indirizzo: yup.string().nullable(),
telefono: yup.string().nullable(),
email: yup.string().nullable(),
login: yup.string().required('login is required'),
azienda: yup.string().required('azienda is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
            <>
                    <p className="no-margin-bottom">
                        <a className="btn-icon-split" onClick={() => hideShowForm(false)}>
                            <p className="no-margin-bottom">
                                <span className="">
                                    <i className="fas fa-arrow-left"></i>
                                </span>
                                <span className="text">Lista</span>
                            </p>
                        </a>
                    </p>
                    <p className="m-0 font-weight-bold text-primary text-capitalize">Nuova Scheda Intervento</p>
                </>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">nome</label>
<Form.Control type="text" name="nome" className="form-control" value={formik.values.nome}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nome && !!formik.errors.nome}
isValid ={!!formik.touched.nome && !formik.errors.nome}
></Form.Control>
{
    formik.errors.nome && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nome}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">cognome</label>
<Form.Control type="text" name="cognome" className="form-control" value={formik.values.cognome}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.cognome && !!formik.errors.cognome}
isValid ={!!formik.touched.cognome && !formik.errors.cognome}
></Form.Control>
{
    formik.errors.cognome && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cognome}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">denominazione</label>
<Form.Control type="text" name="denominazione" className="form-control" value={formik.values.denominazione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.denominazione && !!formik.errors.denominazione}
isValid ={!!formik.touched.denominazione && !formik.errors.denominazione}
></Form.Control>
{
    formik.errors.denominazione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.denominazione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codicefiscale</label>
<Form.Control type="text" name="codicefiscale" className="form-control" value={formik.values.codicefiscale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codicefiscale && !!formik.errors.codicefiscale}
isValid ={!!formik.touched.codicefiscale && !formik.errors.codicefiscale}
></Form.Control>
{
    formik.errors.codicefiscale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codicefiscale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">citta</label>
<Form.Control type="text" name="citta" className="form-control" value={formik.values.citta}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.citta && !!formik.errors.citta}
isValid ={!!formik.touched.citta && !formik.errors.citta}
></Form.Control>
{
    formik.errors.citta && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.citta}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">cap</label>
<Form.Control type="text" name="cap" className="form-control" value={formik.values.cap}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.cap && !!formik.errors.cap}
isValid ={!!formik.touched.cap && !formik.errors.cap}
></Form.Control>
{
    formik.errors.cap && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cap}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">indirizzo</label>
<Form.Control type="text" name="indirizzo" className="form-control" value={formik.values.indirizzo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.indirizzo && !!formik.errors.indirizzo}
isValid ={!!formik.touched.indirizzo && !formik.errors.indirizzo}
></Form.Control>
{
    formik.errors.indirizzo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.indirizzo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">telefono</label>
<Form.Control type="text" name="telefono" className="form-control" value={formik.values.telefono}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.telefono && !!formik.errors.telefono}
isValid ={!!formik.touched.telefono && !formik.errors.telefono}
></Form.Control>
{
    formik.errors.telefono && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.telefono}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">login</label>
<Form.Control as="select"  name="login" className="form-control" value={formik.values.login}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.login && !!formik.errors.login}
isValid ={!!formik.touched.login && !formik.errors.login}
>
<option value={0}>Select Login </option> 
{
loginData.list.map((item, i) => {
return <option value={item.id} key={`login-${i}`}>{item.username}</option>
})}
</Form.Control>
{
    formik.errors.login && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.login}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">azienda</label>
<Form.Control as="select"  name="azienda" className="form-control" value={formik.values.azienda}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.azienda && !!formik.errors.azienda}
isValid ={!!formik.touched.azienda && !formik.errors.azienda}
>
<option value={0}>Select Azienda </option> 
{
aziendaData.list.map((item, i) => {
return <option value={item.id} key={`azienda-${i}`}>{item.denominazione}</option>
})}
</Form.Control>
{
    formik.errors.azienda && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.azienda}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

