export const MenuItemsAzienda = [
    { title: 'Home', path: '/dashboard', icon: 'fas fa-fw fa-home', subMenu: [] },
// { title: 'File Upload', path: '/upload', icon: 'fas fa-fw fa-upload', subMenu: [] },
{ title: 'Utente', path: '/utente', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Accesso Utente', path: '/login', icon: 'fas fa-fw fa-table',subMenu: []},
// { title: 'Tipo_Intervento', path: '/tipo_intervento', icon: 'fas fa-fw fa-table',subMenu: []},
// { title: 'Tipo_Impianto', path: '/tipo_impianto', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Scheda Intervento', path: '/scheda_intervento', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Installatore', path: '/installatore', icon: 'fas fa-fw fa-table',subMenu: []},
{ title: 'Impianto', path: '/impianto', icon: 'fas fa-fw fa-table',subMenu: []},
// { title: 'Condominio', path: '/condominio', icon: 'fas fa-fw fa-table',subMenu: []},
// { title: 'Azienda', path: '/azienda', icon: 'fas fa-fw fa-table',subMenu: []},

]