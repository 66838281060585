import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setImpiantoMessage } from "redux/actions";
import { resetUtenteToInit, setUtenteList } from "redux/actions";
import { resetTipo_ImpiantoToInit, setTipo_ImpiantoList } from "redux/actions";

import { getUtente } from "services/utenteService";
import { getTipo_Impianto } from "services/tipo_impiantoService";

import { useAppDispatch } from "redux/store";
import { addImpianto, updateImpianto } from "services/impiantoService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const ImpiantoForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',utente:'',tipoimpianto:'',nomeimpianto:'',descrizione:'',matricola:'',marca:'',modello:'',potenzanominale:'',richiestadetrazione:'',accumulo:'',capacitaaccumulo:''};
    const initialValue = action === 'edit' ? row : iValue;
    const utenteData = useSelector((state: RootState) => state.utente);
const tipo_impiantoData = useSelector((state: RootState) => state.tipo_impianto);

    useEffect(() => {
if (utenteData && utenteData.list && utenteData.list.length === 0) {
            dispatch(resetUtenteToInit());
            getUtente(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setUtenteList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setImpiantoMessage("No Record Found For Utente"));
                }
              })
        }
if (tipo_impiantoData && tipo_impiantoData.list && tipo_impiantoData.list.length === 0) {
            dispatch(resetTipo_ImpiantoToInit());
            getTipo_Impianto(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setTipo_ImpiantoList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setImpiantoMessage("No Record Found For Tipo_Impianto"));
                }
              })
        }
})
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateImpianto(values);
                if (response) {
                    dispatch(setImpiantoMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setImpiantoMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addImpianto(values);
                if (response) {
                    dispatch(setImpiantoMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setImpiantoMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           nomeimpianto: yup.string().required('nomeimpianto is required'),
descrizione: yup.string().required('descrizione is required'),
matricola: yup.string().nullable(),
marca: yup.string().nullable(),
modello: yup.string().nullable(),
potenzanominale: yup.number().nullable(),
richiestadetrazione: yup.string().nullable(),
accumulo: yup.string().nullable(),
capacitaaccumulo: yup.number().nullable(),
utente: yup.string().required('utente is required'),
tipoimpianto: yup.string().required('tipoimpianto is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
            <>
                    <p className="no-margin-bottom">
                        <a className="btn-icon-split" onClick={() => hideShowForm(false)}>
                            <p className="no-margin-bottom">
                                <span className="">
                                    <i className="fas fa-arrow-left"></i>
                                </span>
                                <span className="text">Lista</span>
                            </p>
                        </a>
                    </p>
                    <p className="m-0 font-weight-bold text-primary text-capitalize">Nuova Scheda Intervento</p>
                </>
            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">nomeimpianto</label>
<Form.Control type="text" name="nomeimpianto" className="form-control" value={formik.values.nomeimpianto}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.nomeimpianto && !!formik.errors.nomeimpianto}
isValid ={!!formik.touched.nomeimpianto && !formik.errors.nomeimpianto}
></Form.Control>
{
    formik.errors.nomeimpianto && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.nomeimpianto}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">descrizione</label>
<Form.Control type="text" name="descrizione" className="form-control" value={formik.values.descrizione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.descrizione && !!formik.errors.descrizione}
isValid ={!!formik.touched.descrizione && !formik.errors.descrizione}
></Form.Control>
{
    formik.errors.descrizione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.descrizione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">matricola</label>
<Form.Control type="text" name="matricola" className="form-control" value={formik.values.matricola}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.matricola && !!formik.errors.matricola}
isValid ={!!formik.touched.matricola && !formik.errors.matricola}
></Form.Control>
{
    formik.errors.matricola && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.matricola}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">marca</label>
<Form.Control type="text" name="marca" className="form-control" value={formik.values.marca}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.marca && !!formik.errors.marca}
isValid ={!!formik.touched.marca && !formik.errors.marca}
></Form.Control>
{
    formik.errors.marca && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.marca}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">modello</label>
<Form.Control type="text" name="modello" className="form-control" value={formik.values.modello}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.modello && !!formik.errors.modello}
isValid ={!!formik.touched.modello && !formik.errors.modello}
></Form.Control>
{
    formik.errors.modello && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.modello}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">potenzanominale</label>
<Form.Control type="text" name="potenzanominale" className="form-control" value={formik.values.potenzanominale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.potenzanominale && !!formik.errors.potenzanominale}
isValid ={!!formik.touched.potenzanominale && !formik.errors.potenzanominale}
></Form.Control>
{
    formik.errors.potenzanominale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.potenzanominale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">richiestadetrazione</label>
<Form.Control type="text" name="richiestadetrazione" className="form-control" value={formik.values.richiestadetrazione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.richiestadetrazione && !!formik.errors.richiestadetrazione}
isValid ={!!formik.touched.richiestadetrazione && !formik.errors.richiestadetrazione}
></Form.Control>
{
    formik.errors.richiestadetrazione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.richiestadetrazione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">accumulo</label>
<Form.Control type="text" name="accumulo" className="form-control" value={formik.values.accumulo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.accumulo && !!formik.errors.accumulo}
isValid ={!!formik.touched.accumulo && !formik.errors.accumulo}
></Form.Control>
{
    formik.errors.accumulo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.accumulo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">capacitaaccumulo</label>
<Form.Control type="text" name="capacitaaccumulo" className="form-control" value={formik.values.capacitaaccumulo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.capacitaaccumulo && !!formik.errors.capacitaaccumulo}
isValid ={!!formik.touched.capacitaaccumulo && !formik.errors.capacitaaccumulo}
></Form.Control>
{
    formik.errors.capacitaaccumulo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.capacitaaccumulo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">utente</label>
<Form.Control as="select"  name="utente" className="form-control" value={formik.values.utente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.utente && !!formik.errors.utente}
isValid ={!!formik.touched.utente && !formik.errors.utente}
>
<option value={0}>Select Utente </option> 
{
utenteData.list.map((item, i) => {
return <option value={item.id} key={`utente-${i}`}>{item.nome}</option>
})}
</Form.Control>
{
    formik.errors.utente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.utente}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">tipoimpianto</label>
<Form.Control as="select"  name="tipoimpianto" className="form-control" value={formik.values.tipoimpianto}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.tipoimpianto && !!formik.errors.tipoimpianto}
isValid ={!!formik.touched.tipoimpianto && !formik.errors.tipoimpianto}
>
<option value={0}>Select Tipo_Impianto </option> 
{
tipo_impiantoData.list.map((item, i) => {
return <option value={item.id} key={`tipo_impianto-${i}`}>{item.descrizione}</option>
})}
</Form.Control>
{
    formik.errors.tipoimpianto && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.tipoimpianto}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

