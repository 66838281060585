
import { APIService } from "services";
import { getUtenteByLogin } from "./utenteService";
import { getInstallatoreLogin } from "./installatoreService";
import { getAziendaLogin } from "./aziendaService";



export const getScheda_Intervento = async (pageNo,pageSize,search) => {
    let res;
    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")
    if(search.length===0) {
        
        
        if(tipoUtente === "UTENTE"){ 
            const utenteData = await getUtenteByLogin(idUtente);
            if (utenteData.data.document == null && utenteData.data.document.records.length > 0){
                idUtente = utenteData.data.document.records[0].id
            }else{
                const utenteData = await getUtenteByLogin(idUtente);
                idUtente = utenteData.data.document.records[0].id
            }
            
            res = await getAllScheda_Intervento_utente(idUtente,pageNo,pageSize);
        }
        if(tipoUtente === "ADMIN")res = await getAllScheda_Intervento(pageNo,pageSize);
        if(tipoUtente === "INSTALLATORE"){

            const installatoreData = await getInstallatoreLogin(idUtente,pageNo,pageSize);
            if (installatoreData.data.document == null && installatoreData.data.document.records.length > 0){
                idUtente = installatoreData.data.document.records[0].id
            }else{
                const installatoreData = await getInstallatoreLogin(idUtente,pageNo,pageSize);
                idUtente = installatoreData.data.document.records[0].azienda
            }

            //res = await getAllScheda_Intervento_Installatore(idUtente, pageNo,pageSize);
            res = await getAllScheda_Intervento_Azienda(idUtente, pageNo,pageSize);
        }
        if(tipoUtente === "AZIENDA"){

            const aziendaData = await getAziendaLogin(idUtente,pageNo,pageSize);
            if (aziendaData.data.document == null && aziendaData.data.document.records.length > 0){
                idUtente = aziendaData.data.document.records[0].id
            }else{
                const installatoreData = await getAziendaLogin(idUtente,pageNo,pageSize);
                idUtente = installatoreData.data.document.records[0].id
            }

            res = await getAllScheda_Intervento_Azienda(idUtente, pageNo,pageSize);
        }

    }
    else{
        try {
            
            if(tipoUtente === "UTENTE"){ 
                const utenteData = await getUtenteByLogin(idUtente);
                if (utenteData.data.document == null && utenteData.data.document.records.length > 0){
                    idUtente = utenteData.data.document.records[0].id
                }else{
                    const utenteData = await getUtenteByLogin(idUtente);
                    idUtente = utenteData.data.document.records[0].id
                }
                
                res = await getAllScheda_Intervento_utente(idUtente,pageNo,pageSize);
            }
            if(tipoUtente === "ADMIN")res = await searchScheda_Intervento(search,pageNo,pageSize);
            if(tipoUtente === "INSTALLATORE"){

                const installatoreData = await getInstallatoreLogin(idUtente,pageNo,pageSize);
                if (installatoreData.data.document == null && installatoreData.data.document.records.length > 0){
                    idUtente = installatoreData.data.document.records[0].id
                }else{
                    const installatoreData = await getInstallatoreLogin(idUtente,pageNo,pageSize);
                    idUtente = installatoreData.data.document.records[0].azienda
                }
    
                //res = await getAllScheda_Intervento_Installatore(idUtente, pageNo,pageSize);
                res = await getAllScheda_Intervento_Azienda(idUtente, pageNo,pageSize);
            }
            if(tipoUtente === "AZIENDA"){

                const aziendaData = await getAziendaLogin(idUtente,pageNo,pageSize);
                if (aziendaData.data.document == null && aziendaData.data.document.records.length > 0){
                    idUtente = aziendaData.data.document.records[0].id
                }else{
                    const installatoreData = await getAziendaLogin(idUtente,pageNo,pageSize);
                    idUtente = installatoreData.data.document.records[0].id
                }
    
                res = await getAllScheda_Intervento_Azienda(idUtente, pageNo,pageSize);
            }
            
    
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllScheda_Intervento = (pageno,pagesize) => {
return APIService.api().get(`/scheda_intervento/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getAllScheda_Intervento_utente = (utente, pageno,pagesize) => {
return APIService.api().get(`/scheda_intervento/read_by_utente.php?utente=${utente}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getAllScheda_Intervento_Installatore = (installatore, pageno,pagesize) => {
return APIService.api().get(`/scheda_intervento/read_by_installatore.php?installatore=${installatore}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getAllScheda_Intervento_Azienda = (azienda, pageno,pagesize) => {
return APIService.api().get(`/scheda_intervento/read_by_azienda.php?azienda=${azienda}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getAllScheda_Intervento_Amministratore = (amministratore, pageno,pagesize) => {
return APIService.api().get(`/scheda_intervento/read_by_amministratore.php?pid=${amministratore}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneScheda_Intervento = (id) => {
return APIService.api().get(`/scheda_intervento/read_one.php?id=${id}`)
}
export const searchScheda_Intervento = (key,pageno,pagesize) => {
return APIService.api().get(`/scheda_intervento/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addScheda_Intervento = (data) => {
return APIService.api().post(`/scheda_intervento/create.php`,data)
}
export const updateScheda_Intervento = (data) => {
return APIService.api().post(`/scheda_intervento/update.php`,data)
}
export const deleteScheda_Intervento_installatore = (id,installatore) => {
return APIService.api().post(`/scheda_intervento/delete.php`,{id:id,installatore:installatore})
}
export const deleteScheda_Intervento = (id) => {
return APIService.api().post(`/scheda_intervento/delete.php`,{id:id})
}