import React from "react";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from "./login";
import Register from "./register";
import AuthenticatedRoute from "components/auth";
import { Dashboard } from "components/dashboard";
import { FileUpload } from "components/upload";
import { NotFound } from "./404";
import { Utente, Tipo_Login, Tipo_Intervento, Tipo_Impianto, Scheda_Intervento, Installatore, Impianto, Azienda} from "components";
import { LoginList } from "components/login";
import RecuperaPassword from "./recuperapassword";
import ResetPassword from "./resetpassword";
import { CambioPassword } from "components/password";
const Pages: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/recupera-password" element={<RecuperaPassword />}></Route>
        <Route path="/reset-password" element={<ResetPassword />}></Route>
        <Route path="/dashboard" element={<AuthenticatedRoute element={<Dashboard />} />}></Route>
        <Route path="/upload" element={<AuthenticatedRoute element={<FileUpload />} />}></Route>
        <Route path="/" element={<AuthenticatedRoute element={<Login />} />}></Route>
        <Route path="/login" element={<AuthenticatedRoute element={<LoginList />} />}></Route>
        <Route path="/cambio-password" element={<AuthenticatedRoute element={<CambioPassword />} />}></Route>
<Route path="/utente" element={<AuthenticatedRoute element={<Utente />} />}></Route>
<Route path="/tipo_login" element={<AuthenticatedRoute element={<Tipo_Login />} />}></Route>
<Route path="/tipo_intervento" element={<AuthenticatedRoute element={<Tipo_Intervento />} />}></Route>
<Route path="/tipo_impianto" element={<AuthenticatedRoute element={<Tipo_Impianto />} />}></Route>
<Route path="/scheda_intervento" element={<AuthenticatedRoute element={<Scheda_Intervento />} />}></Route>
<Route path="/installatore" element={<AuthenticatedRoute element={<Installatore />} />}></Route>
<Route path="/impianto" element={<AuthenticatedRoute element={<Impianto />} />}></Route>
<Route path="/azienda" element={<AuthenticatedRoute element={<Azienda />} />}></Route>

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Pages;

