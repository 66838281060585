import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setUtenteMessage } from "redux/actions";
import { resetLoginToInit, setLoginList } from "redux/actions";

import { getLogin, getLoginAzienda, getLoginByIdRegistrante } from "services/loginService";

import { useAppDispatch } from "redux/store";
import { addUtente, updateUtente } from "services/utenteService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
import { sendEmail } from "services/emailService";
import { getOneAzienda } from "services/aziendaService";
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const UtenteForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',nome:'',cognome:'',codicefiscale:'',citta:'',cap:'',indirizzo:'',telefono:'',email:'',login:''};
    const initialValue = action === 'edit' ? row : iValue;
    const loginData = useSelector((state: RootState) => state.login);
    const [tipoPersona, setTipoPersona] = useState(null);
    const [checkedValueImp, setCheckedValueImp] = useState(null);
    const [checkedValuePer, setCheckedValuePer] = useState(null);


    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")

    useEffect(() => {
if (loginData && loginData.list && loginData.list.length === 0) {
            dispatch(resetLoginToInit());
            getLogin(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                    let listaLogin = []
                    response.records.map((login) =>{
                        if(login.cognome === '')listaLogin.push(login)
                    })
                  dispatch(setLoginList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: listaLogin, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setUtenteMessage("No Record Found For Login"));
                }
              })
        }
        if(formik.values.nome === ' '){
            setTipoPersona(false)
            setCheckedValueImp(true)
            setCheckedValuePer(false)
        }else{
            setCheckedValuePer(true)
            setCheckedValueImp(false)
        }
},[loginData])
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {

            if(!tipoPersona){
                formik.values.nome = " "
            }
            if (action === 'edit') {
                const response = await updateUtente(values);
                if (response) {
                    dispatch(setUtenteMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setUtenteMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addUtente(values);
                if (response) {
                    dispatch(setUtenteMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                    let idAzienda = -1;
                    loginData.list.map((logindata)=>{
                        if(logindata.username === values.codicefiscale) idAzienda = logindata.id_registrante
                    })
                    let nomeAzienda = null;
                    if(idAzienda != -1){
                        getOneAzienda(idAzienda).then((response) =>{
                            nomeAzienda = response.data.document.records[0].denominazione;
                            handleemail(values, nomeAzienda)
                        })
                    }
                } else {
                    dispatch(setUtenteMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           nome: yup.string().required('nome is required'),
cognome: yup.string().required('cognome is required'),
codicefiscale: yup.string().required('codicefiscale is required'),
citta: yup.string().nullable(),
cap: yup.string().nullable(),
indirizzo: yup.string().nullable(),
telefono: yup.string().nullable(),
email: yup.string().nullable(),
login: yup.string().required('login is required'),

        }),
    });

    const handleemail = (values, azienda) => {
        const data = {
            "to": "tecnoinnovis@gmail.com",
            "body": "invio",
            "subject": "Attivazione account MyImpianto",
            'tipo': "PROFILOUTENTE",
            'utente': values.nome + values.cognome,
            'azienda' : azienda,
            'username' : values.codicefiscale,
            'password' : "password"
    
        }
        sendEmail(data).then((response) => {
            console.log(response)
        })
    
      }
      

      const handleChangeTipoPersona = (val) =>{

        setTipoPersona(val)
        if(action === 'add'){
            if(!val){
                formik.values.nome = " "
                setCheckedValueImp(true)
                setCheckedValuePer(false)
            }else{
                formik.values.nome = ""
                setCheckedValueImp(false)
                setCheckedValuePer(true)
            }
        }else{
            if(checkedValueImp){
                setCheckedValueImp(false)
                setCheckedValuePer(true)
            }
            if(checkedValuePer){
                setCheckedValueImp(true)
                setCheckedValuePer(false)
            }


        }
        
      }


    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
            <>
                    <p className="no-margin-bottom">
                        <a className="btn-icon-split" onClick={() => hideShowForm(false)}>
                            <p className="no-margin-bottom">
                                <span className="">
                                    <i className="fas fa-arrow-left"></i>
                                </span>
                                <span className="text">Lista</span>
                            </p>
                        </a>
                    </p>
                    <p className="m-0 font-weight-bold text-primary text-capitalize">Nuovo Utente</p>
                </>

            </Card.Header>
            <Card.Body>
                <div>
                    <span className="padding-r-10">Impresa</span>
                <input
                    type="radio"
                    name="variableName"
                    value="a"
                    onChange={(e) =>
                        handleChangeTipoPersona(false)
                    }
                    className="input-radio"
                    checked={checkedValueImp}
                    />
                    <span className="padding-r-10">Persona Fisica</span>
                <input
                    type="radio"
                    name="variableName"
                    value="b"
                    onChange={(e) =>
                        handleChangeTipoPersona(true)
                    }
                    className="input-radio"
                    checked={checkedValuePer}
                    />

                </div>

                <Form onSubmit={formik.handleSubmit}>
                {tipoPersona != null && tipoPersona && 
                    <>
                    <Form.Group>
                    <label className="form -control-label">nome</label>
                    <Form.Control type="text" name="nome" className="form-control" value={formik.values.nome}
                    onChange={formik.handleChange}
                    onBlur ={formik.handleBlur}
                    isInvalid ={!!formik.touched.nome && !!formik.errors.nome}
                    isValid ={!!formik.touched.nome && !formik.errors.nome}
                    ></Form.Control>
                    {
                        formik.errors.nome && (
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.nome}
                        </Form.Control.Feedback>
                    )}
                    </Form.Group>
                    <Form.Group>
                    <label className="form -control-label">cognome</label>
                    <Form.Control type="text" name="cognome" className="form-control" value={formik.values.cognome}
                    onChange={formik.handleChange}
                    onBlur ={formik.handleBlur}
                    isInvalid ={!!formik.touched.cognome && !!formik.errors.cognome}
                    isValid ={!!formik.touched.cognome && !formik.errors.cognome}
                    ></Form.Control>
                    {
                        formik.errors.cognome && (
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.cognome}
                        </Form.Control.Feedback>
                    )}
                    </Form.Group>
                    </>
                }
                {tipoPersona != null && !tipoPersona && 
                    <>
                    <Form.Group>
                    <label className="form -control-label">Denominazione Azienda</label>
                    <Form.Control type="text" name="cognome" className="form-control" value={formik.values.cognome}
                    onChange={formik.handleChange}
                    onBlur ={formik.handleBlur}
                    isInvalid ={!!formik.touched.cognome && !!formik.errors.cognome}
                    isValid ={!!formik.touched.cognome && !formik.errors.cognome}
                    ></Form.Control>
                    {
                        formik.errors.cognome && (
                        <Form.Control.Feedback type="invalid">
                            {formik.errors.cognome}
                        </Form.Control.Feedback>
                    )}
                    </Form.Group>
                    </>
                }    
                  
<Form.Group>
<label className="form -control-label">codicefiscale</label>
<Form.Control type="text" name="codicefiscale" className="form-control" value={formik.values.codicefiscale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codicefiscale && !!formik.errors.codicefiscale}
isValid ={!!formik.touched.codicefiscale && !formik.errors.codicefiscale}
></Form.Control>
{
    formik.errors.codicefiscale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codicefiscale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">citta</label>
<Form.Control type="text" name="citta" className="form-control" value={formik.values.citta}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.citta && !!formik.errors.citta}
isValid ={!!formik.touched.citta && !formik.errors.citta}
></Form.Control>
{
    formik.errors.citta && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.citta}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">cap</label>
<Form.Control type="text" name="cap" className="form-control" value={formik.values.cap}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.cap && !!formik.errors.cap}
isValid ={!!formik.touched.cap && !formik.errors.cap}
></Form.Control>
{
    formik.errors.cap && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cap}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">indirizzo</label>
<Form.Control type="text" name="indirizzo" className="form-control" value={formik.values.indirizzo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.indirizzo && !!formik.errors.indirizzo}
isValid ={!!formik.touched.indirizzo && !formik.errors.indirizzo}
></Form.Control>
{
    formik.errors.indirizzo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.indirizzo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">telefono</label>
<Form.Control type="text" name="telefono" className="form-control" value={formik.values.telefono}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.telefono && !!formik.errors.telefono}
isValid ={!!formik.touched.telefono && !formik.errors.telefono}
></Form.Control>
{
    formik.errors.telefono && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.telefono}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">login</label>
<Form.Control as="select"  name="login" className="form-control" value={formik.values.login}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.login && !!formik.errors.login}
isValid ={!!formik.touched.login && !formik.errors.login}
>
<option value={0}>Select Login </option> 
{
loginData.list.map((item, i) => {
return <option value={item.id} key={`login-${i}`}>{item.username} - {item.cognome} {item.nome}</option>
})}
</Form.Control>
{
    formik.errors.login && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.login}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

