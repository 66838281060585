import React, { FC, ReactNode } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { List } from 'reselect/es/types';
import { getViewFileArchivioScheda } from 'services/documentiService';
import { brotliDecompress } from 'zlib';

type Props = {
    show: boolean;
    title: ReactNode;
    body: ReactNode;
    buttonPositive: ReactNode;
    buttonNegative: ReactNode;
    disableButton?: boolean;
    onNegative: () => void;
};

const DetailLogin: FC<Props> = ({
    show,
    title,
    body,
    buttonPositive,
    buttonNegative,
    onNegative,
    disableButton,
}) => {

    console.log(body)
    if(body != null){
        return (
            <Modal size="xl" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-sm-12 heigthRigaElenco'>Username: <strong>{body["username"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Cognome Nome: <strong>{body["cognome"] + " " + body["nome"] }</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Tipo: <strong>{body["tipoutente"]}</strong></div>
                        <div className='col-sm-6 heigthRigaElenco'>Registrante: <strong>{body["tipo_registrante"]}</strong></div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                    {/* <Button onClick={() => onPositive()} variant="primary" disabled={disableButton}>
                        {buttonPositive}
                    </Button> */}
                </Modal.Footer>
            </Modal>
        );
    }else{
        return(
            <Modal size="lg" show={show}  dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Richiesta scaduta, riprova la ricerca e richiedere dettaglio</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" style={{ marginRight: 'auto' }} onClick={() => onNegative()} disabled={disableButton}>
                        {buttonNegative}
                    </Button>
                </Modal.Footer>
            </Modal>
        );

    }
};

export default DetailLogin;

