import { APIService } from "services";
import { getInstallatoreLogin } from "./installatoreService";

export const getAzienda = async (pageNo,pageSize,search) => {
    let res;
    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")
    
    if(search.length===0) {
        if(tipoUtente === "AZIENDA"){
            res = await getAziendaLogin(idUtente, pageNo,pageSize);
        }else if(tipoUtente === "INSTALLATORE"){
            const utenteData = await getInstallatoreLogin(idUtente,pageNo, pageSize).then(result => {return result});
            console.log(utenteData)
            idUtente = utenteData.data.document.records[0].azienda
            res = await getOneAzienda(idUtente).then(result => {return result});
            
        }else if(tipoUtente === "ADMIN"){
            res = await getAllAzienda(pageNo,pageSize);
        }else{
            res = null;
        }
    }
    else{
        try {
            res = await searchAzienda(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllAzienda = (pageno,pagesize) => {
return APIService.api().get(`/azienda/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneAzienda = (id) => {
return APIService.api().get(`/azienda/read_one.php?id=${id}`)
}
export const getAziendaLogin = (id, pageno,pagesize) => {
return APIService.api().get(`/azienda/read_by_login.php?login=${id}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const searchAzienda = (key,pageno,pagesize) => {
return APIService.api().get(`/azienda/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addAzienda = (data) => {
return APIService.api().post(`/azienda/create.php`,data)
}
export const updateAzienda = (data) => {
return APIService.api().post(`/azienda/update.php`,data)
}
export const deleteAzienda = (id) => {
return APIService.api().post(`/azienda/delete.php`,{id:id})
}
