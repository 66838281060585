import { APIService } from "services";
import { getUtenteByLogin } from "./utenteService";
import { getAziendaLogin } from "./aziendaService";

export const getInstallatore = async (pageNo,pageSize,search) => {
    let res;
    const tipoUtente = localStorage.getItem("user")
    let idUtente = localStorage.getItem("pid")

    if(search.length===0) {
        if(tipoUtente === "UTENTE"){ 
            const utenteData = await getUtenteByLogin(idUtente).then(result => {return result});
            if (utenteData.data.document == null && utenteData.data.document.records.length > 0){
                idUtente = utenteData.data.document.records[0].id
            }else{
                const utenteData = await getUtenteByLogin(idUtente);
                idUtente = utenteData.data.document.records[0].id
            }
            
            res = await getInstallatoreUtente(idUtente,pageNo,pageSize);
        }
        if(tipoUtente === "ADMIN")res = await getAllInstallatore(pageNo,pageSize).then(result => {return result});
        if(tipoUtente === "INSTALLATORE") res = await getInstallatoreLogin(idUtente, pageNo,pageSize).then(result => {return result});
        if(tipoUtente === "AZIENDA"){ 
            const utenteData = await getAziendaLogin(idUtente, pageNo,pageSize).then(result => {return result});
            idUtente = utenteData.data.document.records[0].id
            res = await getInstallatoreAzienda(idUtente,pageNo,pageSize).then(result => {return result});
        }
    }
    else{
        try {
            res = await searchInstallatore(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllInstallatore = (pageno,pagesize) => {
return APIService.api().get(`/installatore/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneInstallatore = (id) => {
return APIService.api().get(`/installatore/read_one.php?id=${id}`)
}
export const getInstallatoreUtente = (utente, pageno,pagesize) => {
return APIService.api().get(`/installatore/read_by_utente.php?utente=${utente}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getInstallatoreLogin = (id, pageno,pagesize) => {
return APIService.api().get(`/installatore/read_by_login.php?login=${id}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const getInstallatoreAzienda = (id, pageno,pagesize) => {
return APIService.api().get(`/installatore/read_by_azienda.php?azienda=${id}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const searchInstallatore = (key,pageno,pagesize) => {
return APIService.api().get(`/installatore/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addInstallatore = (data) => {
return APIService.api().post(`/installatore/create.php`,data)
}
export const updateInstallatore = (data) => {
return APIService.api().post(`/installatore/update.php`,data)
}
export const deleteInstallatore = (id) => {
return APIService.api().post(`/installatore/delete.php`,{id:id})
}
