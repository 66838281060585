
import { APIService } from "services";

export const tokenAPICALL = async (user, pass) => {
try{
    const response = await APIService.apiNoAuth().post(`/token/generate.php`, {
        "username": user,
        "password": pass
    }, {
        headers: {
            'Content-Type': 'application/json; charset=UTF-8'
        }
    })

    if (response && response.status === 200) {
        const data = response.data;
        const now = new Date().getTime();
        const expiryDate = now + data.document.expires_in;
        return { jwtToken: data.document.access_token, expiryDate: expiryDate, user: data.user };
    } else {
        return null;
    }
}catch(error){
return null;
}
}

export const recuperaPasswordAPICALL = async (user) => {
    try{
        const response = await APIService.apiNoAuth().post(`/token/recupera_password.php`, {
            "username": user,
        }, {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8'
            }
        })
    
        if (response && response.data && response.status === 200) {
            const data = response.data.document.records;
            return data;
        } else {
            return null;
        }
    }catch(error){
    return null;
    }
    }

    export const resetPasswordAPICALL = async (password, confirmpassword, accessToken) => {
        try{
            const response = await APIService.apiNoAuth().post(`/token/reset_password.php`, {
                "password": password,
                "confirmpassword": confirmpassword,
                "access": accessToken
            }, {
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                }
            })
        
            if (response && response.data && response.status === 200) {
                const data = response.data;
                return data;
            } else {
                return null;
            }
        }catch(error){
        return null;
        }
        }

    export const cambioPasswordAPICALL = async (password, prepassword, access) => {
            try{
                const response = await APIService.api().post(`/token/cambio_password.php`, {
                    "prepassword": prepassword,
                    "password": password,
                    "access": access
                }, {
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8'
                    }
                })
            
                if (response && response.data && response.status === 200) {
                    const data = response.data;
                    return data;
                } else {
                    return null;
                }
            }catch(error){
            return null;
            }
            }