import { APIService } from "services";

export const getTipo_Login = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllTipo_Login(pageNo,pageSize);
    }
    else{
        try {
            res = await searchTipo_Login(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllTipo_Login = (pageno,pagesize) => {
return APIService.api().get(`/tipo_login/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneTipo_Login = (id) => {
return APIService.api().get(`/tipo_login/read_one.php?id=${id}`)
}
export const searchTipo_Login = (key,pageno,pagesize) => {
return APIService.api().get(`/tipo_login/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addTipo_Login = (data) => {
return APIService.api().post(`/tipo_login/create.php`,data)
}
export const updateTipo_Login = (data) => {
return APIService.api().post(`/tipo_login/update.php`,data)
}
export const deleteTipo_Login = (id) => {
return APIService.api().post(`/tipo_login/delete.php`,{id:id})
}
