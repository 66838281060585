import { APIService } from "services";

export const getTipo_Intervento = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllTipo_Intervento(pageNo,pageSize);
    }
    else{
        try {
            res = await searchTipo_Intervento(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllTipo_Intervento = (pageno,pagesize) => {
return APIService.api().get(`/tipo_intervento/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneTipo_Intervento = (id) => {
return APIService.api().get(`/tipo_intervento/read_one.php?id=${id}`)
}
export const searchTipo_Intervento = (key,pageno,pagesize) => {
return APIService.api().get(`/tipo_intervento/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addTipo_Intervento = (data) => {
return APIService.api().post(`/tipo_intervento/create.php`,data)
}
export const updateTipo_Intervento = (data) => {
return APIService.api().post(`/tipo_intervento/update.php`,data)
}
export const deleteTipo_Intervento = (id) => {
return APIService.api().post(`/tipo_intervento/delete.php`,{id:id})
}
