import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IImpianto {
id:number,
utente:number,
tipoimpianto:number,
nomeimpianto:string,
descrizione:string,
matricola?:string,
marca?:string,
modello?:string,
potenzanominale?:number,
richiestadetrazione?:string,
accumulo?:string,
capacitaaccumulo?:number
}

interface IImpiantoData {
    list?: Array<IImpianto>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IImpiantoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const impiantoSlice = createSlice({
    name: "impianto",
    initialState,
    reducers: {
        setImpiantoList: (state, _action: PayloadAction<IImpiantoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetImpiantoToInit: (state) => {
            state = initialState;
        },
        setImpiantoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setImpiantoList, resetImpiantoToInit, setImpiantoMessage } = impiantoSlice.actions;

export default impiantoSlice.reducer;

