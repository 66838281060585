import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IScheda_Intervento {
id:number,
tipointevento?:number,
data?:Date,
descrizione?:string,
indirizzo:string,
foto?:string,
tiporinnovo?:number,
costo?:number,
azienda:number,
installatore?:number,
impianto?:number,
utente?:number
}

interface IScheda_InterventoData {
    list?: Array<IScheda_Intervento>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: IScheda_InterventoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const scheda_interventoSlice = createSlice({
    name: "scheda_intervento",
    initialState,
    reducers: {
        setScheda_InterventoList: (state, _action: PayloadAction<IScheda_InterventoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetScheda_InterventoToInit: (state) => {
            state = initialState;
        },
        setScheda_InterventoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setScheda_InterventoList, resetScheda_InterventoToInit, setScheda_InterventoMessage } = scheda_interventoSlice.actions;

export default scheda_interventoSlice.reducer;

