

export const convertData = (data: string) => {

    let data_elaborata = "";
    if(data != ""){
        
        let anno = data.substring(0,4);
        let mese = data.substring(5,7);
        let giorno = data.substring(8,10);
    
        data_elaborata = giorno + "/"+ mese + "/" + anno;
    }
    

    return data_elaborata;
}