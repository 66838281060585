import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { RootState } from "redux/reducers";
import { setAziendaMessage } from "redux/actions";
import { resetLoginToInit, setLoginList } from "redux/actions";

import { getLogin } from "services/loginService";

import { useAppDispatch } from "redux/store";
import { addAzienda, updateAzienda } from "services/aziendaService";
import { Constant } from "template/Constant";
import * as yup from 'yup';
type Props = {
    row?: any,
    hideShowForm: (actionName) => void;
    getData: (page, pageSize, searchKey) => void;
    action?: string
};
export const AziendaForm: React.FC<Props> = ({ row, hideShowForm, getData, action }) => {
    const dispatch = useAppDispatch();
    const iValue={id:'',denominazione:'',referente:'',codicefiscale:'',citta:'',cap:'',indirizzo:'',telefono:'',email:'',login:''};
    const initialValue = action === 'edit' ? row : iValue;
    const loginData = useSelector((state: RootState) => state.login);

    useEffect(() => {
if (loginData && loginData.list && loginData.list.length === 0) {
            dispatch(resetLoginToInit());
            getLogin(Constant.defaultPageNumber, Constant.defaultDropdownPageSize, '').then((response) => {
                if (response && response.records) {
                  dispatch(setLoginList({ pageNo: Constant.defaultPageNumber, pageSize: Constant.defaultDropdownPageSize, list: response.records, totalCount: response.total_count, searchKey: '' }));
                } else {
                  dispatch(setAziendaMessage("No Record Found For Login"));
                }
              })
        }
})
    const formik = useFormik({
        initialValues: initialValue,
        onSubmit: async (values) => {
            if (action === 'edit') {
                const response = await updateAzienda(values);
                if (response) {
                    dispatch(setAziendaMessage("Updated Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setAziendaMessage("Some error occured!"));
                }
            } else if (action === 'add') {
                const response = await addAzienda(values);
                if (response) {
                    dispatch(setAziendaMessage("Added Successfully"));
                    getData(Constant.defaultPageNumber, Constant.defaultPageSize, '');
                    hideShowForm('');
                } else {
                    dispatch(setAziendaMessage("Some error occured!"));
                }
            }
        },
        validationSchema: yup.object({
           denominazione: yup.string().nullable(),
referente: yup.string().nullable(),
codicefiscale: yup.string().nullable(),
citta: yup.string().nullable(),
cap: yup.string().nullable(),
indirizzo: yup.string().nullable(),
telefono: yup.string().nullable(),
email: yup.string().nullable(),
login: yup.string().required('login is required'),

        }),
    });
    return (
        <Card className="shadow mb-4">
            <Card.Header className="py-3">
            <>
                    <p className="no-margin-bottom">
                        <a className="btn-icon-split" onClick={() => hideShowForm(false)}>
                            <p className="no-margin-bottom">
                                <span className="">
                                    <i className="fas fa-arrow-left"></i>
                                </span>
                                <span className="text">Lista</span>
                            </p>
                        </a>
                    </p>
                    <p className="m-0 font-weight-bold text-primary text-capitalize">Nuova Scheda Intervento</p>
                </>

            </Card.Header>
            <Card.Body>
                <Form onSubmit={formik.handleSubmit}>
                  <Form.Group>
<label className="form -control-label">denominazione</label>
<Form.Control type="text" name="denominazione" className="form-control" value={formik.values.denominazione}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.denominazione && !!formik.errors.denominazione}
isValid ={!!formik.touched.denominazione && !formik.errors.denominazione}
></Form.Control>
{
    formik.errors.denominazione && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.denominazione}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">referente</label>
<Form.Control type="text" name="referente" className="form-control" value={formik.values.referente}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.referente && !!formik.errors.referente}
isValid ={!!formik.touched.referente && !formik.errors.referente}
></Form.Control>
{
    formik.errors.referente && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.referente}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">codicefiscale</label>
<Form.Control type="text" name="codicefiscale" className="form-control" value={formik.values.codicefiscale}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.codicefiscale && !!formik.errors.codicefiscale}
isValid ={!!formik.touched.codicefiscale && !formik.errors.codicefiscale}
></Form.Control>
{
    formik.errors.codicefiscale && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.codicefiscale}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">citta</label>
<Form.Control type="text" name="citta" className="form-control" value={formik.values.citta}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.citta && !!formik.errors.citta}
isValid ={!!formik.touched.citta && !formik.errors.citta}
></Form.Control>
{
    formik.errors.citta && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.citta}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">cap</label>
<Form.Control type="text" name="cap" className="form-control" value={formik.values.cap}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.cap && !!formik.errors.cap}
isValid ={!!formik.touched.cap && !formik.errors.cap}
></Form.Control>
{
    formik.errors.cap && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.cap}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">indirizzo</label>
<Form.Control type="text" name="indirizzo" className="form-control" value={formik.values.indirizzo}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.indirizzo && !!formik.errors.indirizzo}
isValid ={!!formik.touched.indirizzo && !formik.errors.indirizzo}
></Form.Control>
{
    formik.errors.indirizzo && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.indirizzo}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">telefono</label>
<Form.Control type="text" name="telefono" className="form-control" value={formik.values.telefono}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.telefono && !!formik.errors.telefono}
isValid ={!!formik.touched.telefono && !formik.errors.telefono}
></Form.Control>
{
    formik.errors.telefono && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.telefono}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">email</label>
<Form.Control type="text" name="email" className="form-control" value={formik.values.email}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.email && !!formik.errors.email}
isValid ={!!formik.touched.email && !formik.errors.email}
></Form.Control>
{
    formik.errors.email && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.email}
    </Form.Control.Feedback>
)}
</Form.Group>
<Form.Group>
<label className="form -control-label">login</label>
<Form.Control as="select"  name="login" className="form-control" value={formik.values.login}
onChange={formik.handleChange}
onBlur ={formik.handleBlur}
isInvalid ={!!formik.touched.login && !!formik.errors.login}
isValid ={!!formik.touched.login && !formik.errors.login}
>
<option value={0}>Select Login </option> 
{
loginData.list.map((item, i) => {
return <option value={item.id} key={`login-${i}`}>{item.username}</option>
})}
</Form.Control>
{
    formik.errors.login && (
    <Form.Control.Feedback type="invalid">
        {formik.errors.login}
    </Form.Control.Feedback>
)}
</Form.Group>

                    <Form.Group>
                        <Button type="submit" className="float-right" variant="primary">Save</Button>
                    </Form.Group>
                </Form>
            </Card.Body>
        </Card>
    );
}

