import { APIService } from "services";

export const getTipo_Impianto = async (pageNo,pageSize,search) => {
    let res;
    if(search.length===0) {
        res = await getAllTipo_Impianto(pageNo,pageSize);
    }
    else{
        try {
            res = await searchTipo_Impianto(search,pageNo,pageSize);
        } catch(err) {
             return { records:[], totalCount:0 }
        }
    }
    if(res && res.data && res.data.document){
    return res.data.document;
    }else{
    return { records:[], totalCount:0 }
    }
    
}


export const getAllTipo_Impianto = (pageno,pagesize) => {
return APIService.api().get(`/tipo_impianto/read.php?pageno=${pageno}&pagesize=${pagesize}`)
}
export const getOneTipo_Impianto = (id) => {
return APIService.api().get(`/tipo_impianto/read_one.php?id=${id}`)
}
export const searchTipo_Impianto = (key,pageno,pagesize) => {
return APIService.api().get(`/tipo_impianto/search.php?key=${key}&pageno=${pageno}&pagesize=${pagesize}`)
}
export const addTipo_Impianto = (data) => {
return APIService.api().post(`/tipo_impianto/create.php`,data)
}
export const updateTipo_Impianto = (data) => {
return APIService.api().post(`/tipo_impianto/update.php`,data)
}
export const deleteTipo_Impianto = (id) => {
return APIService.api().post(`/tipo_impianto/delete.php`,{id:id})
}
