import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ITipo_Intervento {
id:number,
descrizione?:string
}

interface ITipo_InterventoData {
    list?: Array<ITipo_Intervento>;
    pageNo: number;
    pageSize: number;
    searchKey?: string;
    totalCount?: number;
    message?: string;
}

const initialState: ITipo_InterventoData = {
    pageNo: 1,
    pageSize: 20,
    searchKey: '',
    list: [],
    totalCount: 0,
    message: '',
};

const tipo_interventoSlice = createSlice({
    name: "tipo_intervento",
    initialState,
    reducers: {
        setTipo_InterventoList: (state, _action: PayloadAction<ITipo_InterventoData>) => {
            state.list = _action.payload.list;
            state.pageNo = _action.payload.pageNo;
            state.pageSize = _action.payload.pageSize;
            state.totalCount = _action.payload.totalCount;
        },
        resetTipo_InterventoToInit: (state) => {
            state = initialState;
        },
        setTipo_InterventoMessage: (state, _action: PayloadAction<string>) => {
            state.message = _action.payload;
        },
    },
});

export const { setTipo_InterventoList, resetTipo_InterventoToInit, setTipo_InterventoMessage } = tipo_interventoSlice.actions;

export default tipo_interventoSlice.reducer;

